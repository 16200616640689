import React from "react";

export const Expired = () => {
  return (
    <svg
      width={240}
      height={185}
      viewBox="0 0 167 231"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity={0.2}
        d="M103.868 165.295s-50.54 29.85-50.54 58.131h101.094c-.007-28.281-50.554-58.131-50.554-58.131z"
        fill="#407BFF"
      />
      <path
        opacity={0.2}
        d="M103.867 200.91s-46.668 11.564-46.668 22.517h93.337c0-10.953-46.669-22.517-46.669-22.517z"
        fill="#407BFF"
      />
      <path
        opacity={0.3}
        d="M110.002 173.536c9.49 9.336 27.935 30.022 27.935 49.89H125.29c0-19.868-10.096-40.554-15.288-49.89z"
        fill="#fff"
      />
      <path
        opacity={0.2}
        d="M103.868 165.294s-50.54-29.85-50.54-58.131h101.094c-.007 28.281-50.554 58.131-50.554 58.131z"
        fill="#407BFF"
      />
      <path
        opacity={0.2}
        d="M103.867 162.571s-25.803-12.383-25.803-24.147h51.613c0 11.743-25.81 24.147-25.81 24.147z"
        fill="#407BFF"
      />
      <path
        opacity={0.3}
        d="M98.36 157.053c-9.484-9.336-27.908-30.022-27.908-49.89h12.647c-.028 19.868 10.047 40.554 15.26 49.89zM110.002 157.053c9.49-9.336 27.474-32.416 27.935-49.89h-3.37c-1.582 21.306-17.055 40.857-24.565 49.89z"
        fill="#fff"
      />
      <path
        d="M147.551 97.182h-5.728v.688h5.728v-.688zM167 106.634h-.688a8.77 8.77 0 00-8.754-8.764h-6.045v-.688h6.045a9.461 9.461 0 019.442 9.452z"
        fill="#407BFF"
      />
      <path
        d="M157.551 99.59H50.184a7.043 7.043 0 00-7.042 7.044v1.06a7.043 7.043 0 007.042 7.044h107.367a7.043 7.043 0 007.042-7.044v-1.06a7.043 7.043 0 00-7.042-7.044zM157.551 215.853H50.184a7.043 7.043 0 00-7.042 7.044v1.06a7.043 7.043 0 007.042 7.044h107.367a7.043 7.043 0 007.042-7.044v-1.06a7.043 7.043 0 00-7.042-7.044z"
        fill="#407BFF"
      />
      <path
        d="M15.242 154.143l-4.663-4.141 7.95-12.211 4.663 4.135-7.95 12.217z"
        fill="#FF8B7B"
      />
      <path
        d="M11.542 147.374l5.928 4.128a.451.451 0 01.144.626l-2.97 4.898a1.145 1.145 0 01-1.575.296c-2.064-1.466-2.827-2.181-5.296-4.176a91.81 91.81 0 01-7.015-6.116c-1.829-1.899.103-3.735 1.073-3.082 2.29 1.555 6.272 3.268 8.576 3.13.4-.031.8.073 1.135.296z"
        fill="#263238"
      />
      <path
        opacity={0.2}
        d="M23.185 141.932l-4.663-4.141-3.824 5.882 4.78 3.969 3.707-5.71z"
        fill="#000"
      />
      <path
        d="M70.624 80.932s-31.291-2.14-35.17 8.468c-8.824 24.119-20.302 49.704-20.302 49.704l9.223 6.68S43.87 115.962 49.82 96.816c12.035 2.924 33.795 5.421 40.802-2.628 5.984-6.88 1.507-13.256 1.507-13.256H70.624z"
        fill="#263238"
      />
      <path
        opacity={0.3}
        d="M43.823 99.679c-.729 2.944-1.836 11.378-2.524 16.971a172.36 172.36 0 004.264-8.847l-1.74-8.124z"
        fill="#000"
      />
      <path
        d="M23.783 148.805l-10.46-8.201 2.139-4.595 11.69 8.936-3.37 3.86zM9.031 146.995a8.414 8.414 0 001.54.791.149.149 0 00.158-.036.15.15 0 00.035-.156c-.089-.234-.873-2.29-1.691-2.49a.57.57 0 00-.53.152.833.833 0 00-.31.777c.14.407.424.75.798.962zm1.328.379c-1.08-.468-1.754-.984-1.83-1.376a.536.536 0 01.22-.502.26.26 0 01.255-.075c.44.11 1.01 1.134 1.355 1.953z"
        fill="#407BFF"
      />
      <path
        d="M10.538 147.766a.115.115 0 00.069 0 .162.162 0 00.075-.01.16.16 0 00.062-.045c.048-.055 1.218-1.493 1.046-2.38a.753.753 0 00-.406-.53.574.574 0 00-.688.062c-.523.488-.502 2.139-.193 2.821a.187.187 0 00.035.082zm.763-2.635a.457.457 0 01.193.289c.103.523-.454 1.424-.819 1.92-.192-.688-.13-1.913.207-2.229.069-.062.165-.124.364 0l.055.02z"
        fill="#407BFF"
      />
      <path
        d="M56.353 161.36l-6.017 1.204-1.114-16.119 6.114.193 1.017 14.722z"
        fill="#FF8B7B"
      />
      <path
        d="M49.518 160.362l6.877-2.896a.462.462 0 01.633.227l2.626 5.283a1.184 1.184 0 01-.612 1.541c-2.407.97-3.603 1.308-6.622 2.587-1.857.784-6.877 3.055-9.56 3.722-2.682.667-3.287-2.023-2.2-2.545 2.572-1.239 6.836-5.016 7.984-7.1.196-.36.502-.647.874-.819z"
        fill="#263238"
      />
      <path
        d="M47.805 162.343a8.458 8.458 0 001.533-.908.164.164 0 00.062-.158.168.168 0 00-.045-.076.158.158 0 00-.078-.041c-.248-.055-2.476-.502-3.102.103a.569.569 0 00-.158.543.847.847 0 00.502.688 1.8 1.8 0 001.286-.151zm1.052-.949c-.997.688-1.816.983-2.221.825a.541.541 0 01-.317-.468.273.273 0 01.07-.268c.336-.323 1.574-.241 2.468-.089z"
        fill="#407BFF"
      />
      <path
        d="M49.304 161.456a.227.227 0 00.062-.049.156.156 0 000-.144c0-.076-.633-1.891-1.5-2.228a.753.753 0 00-.35-.051.75.75 0 00-.337.113.573.573 0 00-.33.646c.137.688 1.588 1.616 2.352 1.719a.136.136 0 00.103-.006zm-1.878-2.125a.459.459 0 01.358 0c.516.199.99 1.182 1.224 1.774-.688-.227-1.74-.935-1.83-1.417 0-.089 0-.206.187-.316l.061-.041z"
        fill="#407BFF"
      />
      <path
        opacity={0.2}
        d="M55.342 146.645l-6.12-.2.584 8.421 6.093-.11-.557-8.111z"
        fill="#000"
      />
      <path
        d="M85.822 80.933s-44.702 4.23-43.093 17.693c2.352 19.421 4.072 53.426 4.072 53.426l11.395.447s4.278-29.898 1.775-49.786c11.574 0 36.717.612 41.139-5.373 5.247-7.1 2.819-16.407 2.819-16.407H85.822z"
        fill="#263238"
      />
      <path
        d="M59.455 153.2l-13.68-.681-.487-5.951 14.999 1.032-.832 5.6zM75.898 41.506a64.367 64.367 0 01-.357 3.474c-.152 1.15-.296 2.27-.475 3.406-.33 2.263-.784 4.513-1.293 6.776a63.737 63.737 0 01-1.918 6.797 36.226 36.226 0 01-3.116 6.88 17.199 17.199 0 01-2.75 3.501c-.283.264-.579.512-.888.743a11.85 11.85 0 01-.853.633c-.55.388-1.121.744-1.712 1.066a33.295 33.295 0 01-6.877 2.8c-1.135.351-2.276.633-3.439.888-1.162.254-2.27.474-3.438.653a55.298 55.298 0 01-6.877.688 2.352 2.352 0 01-.826-4.589l.09-.034a110.491 110.491 0 0011.849-4.513 32.32 32.32 0 005.075-2.875c.365-.262.688-.53 1.018-.798.152-.131.31-.262.44-.4.122-.109.237-.226.344-.35a9.44 9.44 0 001.17-1.796 29.595 29.595 0 002-5.29 70.103 70.103 0 001.438-5.999c.399-2.05.756-4.128 1.093-6.233.337-2.105.592-4.251.826-6.246v-.117a4.726 4.726 0 015.145-4.035 4.724 4.724 0 014.249 4.97h.082z"
        fill="#407BFF"
      />
      <path
        d="M42.132 73.984l-3.44.214 2.752 6.329s5.948-.131 5.825-3.062l-1.073-1.376a5.6 5.6 0 00-4.064-2.105zM34.924 74.72l2.971 5.82h3.542l-2.75-6.33-3.763.51z"
        fill="#FF8B7B"
      />
      <path
        d="M75.403 31.75l6.65-1.375 5.296-.764a9.815 9.815 0 012.29-.103 10.024 10.024 0 018.761 7.326c1.328 4.816 2.524 10.725 3.439 15.96 1.279 7.272 1.939 18.197 2.063 28.13H72.398c-.688-17.989-4.512-29.58-5.811-36.77a10.061 10.061 0 015.563-10.863l3.253-1.54z"
        fill="#407BFF"
      />
      <path
        d="M72.398 35.708c.076 2.958.688 13.504 3.858 19.496.557-5.614 7.221-22.702 7.221-22.702l-11.08 3.206z"
        fill="#F5F5F5"
      />
      <path
        opacity={0.3}
        d="M102.561 57.735c0-.11 0-.213-.041-.33l-.062-.496-.103-.79-.103-.737-.104-.688c0-.193-.061-.392-.089-.578-.069-.44-.137-.867-.213-1.286a230.531 230.531 0 00-.729-3.963v-.11c-.261-1.376-.53-2.703-.819-4.045v-.09a63.91 63.91 0 00-.37-1.712l-.145-.633-.248-1.094-.206-.853-.041-.18a112.71 112.71 0 00-.846-3.28l-4.78-2.518c.55 2.112 1.073 4.437 1.568 6.817-.572.3-1.1.677-1.568 1.121-1.142 1.019-1.1 3.186.041 5.752a63.619 63.619 0 015.158 23.19c.145 3.24.234 6.522.275 9.707h4.814c-.137-7.89-.577-16.394-1.389-23.204z"
        fill="#000"
      />
      <path
        d="M79.577 18.494l-2.703 4.128-3.672 5.607a5.743 5.743 0 011.135 1.658c.687 1.375 1.224 2.366-1.617 4.3-.605.412-.625 1.065-.488 1.788.165.88.688 1.802 3.741 1.259a14.132 14.132 0 007.69-4.266c1.017-1.025.783-1.788-.2-2.848-2.779-2.992-3.61-7.773-3.886-11.626z"
        fill="#FF8B7B"
      />
      <path
        d="M73.085 37.337l1.946 1.224 3.164-1.341a5.11 5.11 0 00-2.751-1.438 5.446 5.446 0 00-2.359 1.555z"
        fill="#263238"
      />
      <path
        d="M75.41 35.783c1.451.598 2.18 1.947 2.84 3.501 0 0 6.953-5.27 7.098-8.743-.372-1.184-2.112-.977-2.112-.977s-2.118 3.673-7.826 6.219zM75.41 35.782a3.528 3.528 0 00-2.201 3.44s-3.095-3.632-.578-6.377a9.137 9.137 0 012.16-.805s-.757 1.514.619 3.742z"
        fill="#fff"
      />
      <path
        d="M75.644 37.991h-.888c-.288.495-.687 6.948-.845 10.381a30.737 30.737 0 001.863 5.84.283.283 0 000 .07c.049.11.097.213.152.316.096.206.192.413.302.605a38.822 38.822 0 011.479-6.28c-.908-3.977-2.063-10.932-2.063-10.932z"
        fill="#263238"
      />
      <path
        opacity={0.2}
        d="M74.11 26.847l2.256-3.997c.041.096.096.213.151.323.198.499.36 1.012.482 1.534.412 1.782-.963 4.313-2.483 5.504-.062-.11-.117-.227-.179-.344A6.88 6.88 0 0073.3 28.27l-.09-.07.901-1.354z"
        fill="#000"
      />
      <path
        d="M63.12 18.26c-.543-2.441 3.343-5.82 3.343-5.82l-4.601-1.602c-2.077 1.493-.729 5.751 1.258 7.423z"
        fill="#263238"
      />
      <path
        d="M78.622 11.202c1.086 6.109 2.063 9.631-.234 13.532a8.054 8.054 0 01-11.384 2.822 8.056 8.056 0 01-2.873-3.235c-2.578-5.036-3.92-14.172 1.328-18.182a8.148 8.148 0 0113.163 5.063z"
        fill="#FF8B7B"
      />
      <path
        d="M78.704 17.38l-2.888-.791v-3.715s-3.652-2.064-3.088-4.644c.28-1.45.673-2.876 1.176-4.265 0 0 5.79-.372 7.565.371-.46.499-.959.959-1.492 1.376 1.388.961 2.579 2.18 3.507 3.591a4.814 4.814 0 00-1.98.957s.756 5.097-2.8 7.12z"
        fill="#263238"
      />
      <path
        d="M72.06.003c2.126 1.334.895 3.783.895 3.783l5.687.117c-.303-1.795-5.233-3.99-6.581-3.9z"
        fill="#263238"
      />
      <path
        d="M74.282 9.483c-.406 1.142-5.013 2.546-9.215 2.683a19.059 19.059 0 01-6.135-1.155 7.013 7.013 0 012.063-1.466c-2.537-.626-5.749-2.855-6.636-4.67a10.505 10.505 0 015.777-.372A6.19 6.19 0 0157.956.23S68.71 4.496 72.852 2.728C77.349.8 80.767 4.413 77.81 7.165c-2.957 2.752-3.528 2.318-3.528 2.318z"
        fill="#263238"
      />
      <path
        d="M81.909 15.625a5.505 5.505 0 01-1.231 3.715c-1.114 1.328-2.847.268-3.246-1.376-.358-1.493.11-3.797 1.575-4.513 1.465-.715 2.826.502 2.902 2.174z"
        fill="#FF8B7B"
      />
      <path
        d="M69.323 16.884c.152.482 0 .956-.275 1.073-.275.117-.687-.186-.846-.688-.158-.502 0-.956.276-1.073.275-.117.687.18.846.688zM63.993 18.811c.152.475 0 .956-.275 1.066-.275.11-.687-.179-.846-.688-.158-.509 0-.956.276-1.066.275-.11.687.207.845.688z"
        fill="#263238"
      />
      <path
        d="M65.816 18.557a16.099 16.099 0 01-.495 4.224 2.414 2.414 0 002.009-.475l-1.514-3.75z"
        fill="#A02724"
      />
      <path
        d="M69.076 24.142A4.368 4.368 0 0071.62 21.9a.152.152 0 00-.195-.196.147.147 0 00-.08.079 4.128 4.128 0 01-2.895 2.229.146.146 0 00-.094.066.144.144 0 00-.016.113.144.144 0 00.172.11c.191-.04.38-.094.564-.159zM70.506 14.064a.296.296 0 00.103-.103.29.29 0 00-.082-.406 2.855 2.855 0 00-2.655-.275.297.297 0 10.255.537 2.236 2.236 0 012.063.233.295.295 0 00.316.014zM62.935 15.915a.268.268 0 000-.145.296.296 0 00-.386-.158 2.819 2.819 0 00-1.733 2.022.296.296 0 00.578.096 2.262 2.262 0 011.376-1.575.295.295 0 00.165-.24z"
        fill="#263238"
      />
      <path
        d="M96.935 33.877a57.57 57.57 0 012.063 2.683c.647.902 1.321 1.796 1.954 2.71 1.286 1.824 2.482 3.716 3.651 5.642a59.78 59.78 0 013.233 6.06c1.06 2.206 1.9 4.51 2.51 6.88.342 1.378.522 2.79.536 4.21-.017 1.42-.22 2.83-.605 4.197a27.828 27.828 0 01-2.971 6.77 38.47 38.47 0 01-1.898 2.916 71.653 71.653 0 01-2.063 2.718 47.097 47.097 0 01-4.67 4.939 2.359 2.359 0 01-3.61-2.945l.062-.103a79.733 79.733 0 005.584-10.842 24.37 24.37 0 001.54-5.262 9.483 9.483 0 00.09-2.195c-.083-.725-.24-1.44-.468-2.133a31.814 31.814 0 00-2.235-5.09c-.915-1.727-1.946-3.44-3.047-5.18-1.1-1.741-2.255-3.44-3.438-5.112l-1.809-2.518c-.605-.819-1.238-1.692-1.808-2.442l-.042-.055a4.723 4.723 0 014.261-7.613 4.72 4.72 0 013.153 1.765h.027zM31.41 84.626l58.51-2.648a1.374 1.374 0 011.466 1.334l-61.31 2.752a1.374 1.374 0 011.334-1.438z"
        fill="#407BFF"
      />
      <path
        opacity={0.6}
        d="M31.41 84.626l58.51-2.648a1.374 1.374 0 011.466 1.334l-61.31 2.752a1.374 1.374 0 011.334-1.438z"
        fill="#fff"
      />
      <path
        d="M22.098 57.474l41.628-1.885a1.3 1.3 0 011.21.874l7.991 25.364a.69.69 0 01-.64.95L30.66 84.66a1.286 1.286 0 01-1.203-.867l-7.991-25.37a.689.689 0 01.632-.95z"
        fill="#407BFF"
      />
      <path
        opacity={0.6}
        d="M22.098 57.474l41.628-1.885a1.3 1.3 0 011.21.874l7.991 25.364a.69.69 0 01-.64.95L30.66 84.66a1.286 1.286 0 01-1.203-.867l-7.991-25.37a.689.689 0 01.632-.95z"
        fill="#fff"
      />
      <path
        opacity={0.5}
        d="M64.95 56.456l7.991 25.364a.688.688 0 01-.64.956L63.74 55.582a1.3 1.3 0 011.21.874zM74.537 82.673l15.384-.688a1.374 1.374 0 011.465 1.335l-18.183.819a1.376 1.376 0 011.334-1.466z"
        fill="#407BFF"
      />
      <path
        d="M93.971 80.368l-3.933.57 4.03 5.608s6.402-1.026 5.66-3.867l-1.376-1.142a5.584 5.584 0 00-4.38-1.17zM85.224 82.618l4.93 4.3 3.914-.372-4.03-5.607-4.814 1.679z"
        fill="#FF8B7B"
      />
    </svg>
  );
};

export const HomeIcon = () => {
  return (
    <svg
      className="main-head-icon"
      viewBox="0 0 274 115"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M263 23.8C265.3 21.6 265.1 18.2 262.5 16.2C259.9 14.2 254.8 14.9 251.9 16.6C253.7 13 251.9 8.5 247.7 6.2C243.5 3.9 237.2 4.1 233.2 6.5C229.2 9 225.9 14.7 228 18.2C225 15.8 219.8 15.3 216 17C212.2 18.7 210.5 22.4 211.9 25.5C226.9 25.2 248 24.2 263 23.8Z"
          fill="url(#paint0_linear)"
        />
        <path
          d="M266.8 100.7C264.2 98.8001 259.5 98.3001 256.5 99.9001C258.7 97.7001 258.2 94.3001 255.5 92.5001C252.8 90.6001 248 90.7001 245.1 92.4001C247.6 90.1001 246.6 86.3001 243.2 84.9001C239.7 83.4001 234.3 84.7001 232.8 87.4001C232.2 84.6001 231.7 81.7001 230 79.1001C228.3 76.5001 225.4 74.2001 221.6 73.4001C215.7 72.2001 219.3 75.2001 216.7 79.2001C214.1 83.2001 213.2 88.5001 214.3 92.9001C213.2 90.9001 209.9 89.6001 206.9 90.1001C203.9 90.6001 201.7 92.8001 202 95.0001C198.7 93.2001 190.9 95.1001 188.9 97.7001C186.9 100.3 188.8 104 192.7 105.1C190.5 104.8 187.1 106.6 186.8 108.2C186.5 109.8 188.4 111.4 190.6 111.5L266.1 111.1C268.3 108.9 269.5 102.6 266.8 100.7Z"
          fill="#65A19F"
        />
        <path
          d="M15.3001 98.5998C17.7001 95.6999 21.7001 95.1999 24.7001 96.9999C25.8001 90.4999 28.9001 84.3998 33.6001 81.5998C38.3001 78.7998 44.6001 79.5998 47.5001 84.9999C47.2001 78.9999 50.5001 73.0999 54.9001 71.2999C59.3001 69.3999 64.4001 71.3998 67.7001 75.6998C70.9001 79.9998 72.8001 86.6998 72.2001 92.6998C76.3001 90.1998 81.5001 91.3999 84.8001 95.4999C88.1001 99.5999 89.2001 104.8 87.3001 110.1H13.0001C11.9001 106.1 12.9001 101.6 15.3001 98.5998Z"
          fill="#65A19F"
        />
        <path
          d="M273.7 109.5C273.7 107 228.3 105 132.9 105C37.4 105 0 107 0 109.5C0 112 37.4 114 132.8 114C228.3 114.1 273.7 112 273.7 109.5Z"
          fill="#46606E"
        />
        <path
          d="M241.5 95.2002V109.3L245.7 109.6L244.5 95.2002H241.5Z"
          fill="#2F3A4F"
        />
        <path
          d="M243 97.3002C255.6 96.8002 244.9 57.8002 239.6 43.7002C232 57.1002 227.2 97.9002 243 97.3002Z"
          fill="#46606E"
        />
        <path
          d="M232.9 83.9004C233.9 91.8004 237 97.6004 243 97.3004C246.2 97.2004 247.9 94.5004 248.6 90.5004C247 90.4004 245.5 90.3004 243.8 90.0004C240.3 88.8004 236 86.9004 232.9 83.9004Z"
          fill="#364954"
        />
        <path
          d="M33.7 95.2002V109.3L37.7999 109.6L36.5999 95.2002H33.7Z"
          fill="#2F3A4F"
        />
        <path
          d="M35.2001 97.3002C47.8001 96.8002 37.1001 57.8002 31.8001 43.7002C24.1001 57.1002 19.3001 97.9002 35.2001 97.3002Z"
          fill="#46606E"
        />
        <path
          d="M25 83.3999C26 91.5999 29 97.4999 35.1 97.2999C38.6 97.1999 40.3 93.9999 40.8 89.1999C34.2 90.4999 29.1 87.8999 25 83.3999Z"
          fill="#364954"
        />
        <path
          d="M81.5001 43.8002C82.9001 42.3002 82.8001 39.8002 81.2001 38.4002C79.6001 37.0002 76.5001 37.5002 74.7001 38.7002C75.8001 36.1002 74.7001 32.9002 72.1001 31.3002C69.5001 29.7002 65.7001 29.8002 63.3001 31.5002C60.8001 33.2002 58.9001 37.3002 60.1001 39.8002C58.3001 38.1002 55.1001 37.8002 52.8001 39.0002C50.5001 40.2002 49.4001 42.8002 50.3001 45.0002C59.5001 44.9002 72.3001 44.1002 81.5001 43.8002Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M51.8001 21.2998C53.2001 19.7998 53.1001 17.2998 51.5001 15.8998C49.9001 14.4998 46.8001 14.9998 45.0001 16.1998C46.1001 13.5998 45.0001 10.3998 42.4001 8.79982C39.8001 7.19982 36.0001 7.29982 33.5001 9.09982C31.0001 10.7998 29.1001 14.8998 30.3001 17.3998C28.5001 15.6998 25.3001 15.3998 23.0001 16.5998C20.7001 17.7998 19.6001 20.3998 20.5001 22.5998C29.7001 22.2998 42.6001 21.5998 51.8001 21.2998Z"
          fill="url(#paint2_linear)"
        />
        <path
          d="M129.7 13.7002H205.3L205.6 109.3H133.2L129.7 13.7002Z"
          fill="#38445E"
        />
        <path d="M215.3 4.2998H166.3V109.3H215.3V4.2998Z" fill="#5B8DC2" />
        <path d="M215.3 4.7002H166.3V7.7002H215.3V4.7002Z" fill="#537CAD" />
        <path
          opacity="0.5"
          d="M170.4 53.6001C171 53.6001 183.1 4.6001 183.1 4.6001H212.7L216.8 53.6001H170.4Z"
          fill="#6ECACC"
        />
        <path
          d="M78.3999 4.2998L80.8999 53.6998H150.8L152.1 4.2998H78.3999Z"
          fill="#D7D7D7"
        />
        <path
          d="M215.3 35.4998V33.8998H209.1V4.2998H207.5V33.8998H176V4.2998H174.4V33.8998H166.2L160.7 35.4998H174.4V76.9998H166.1L166.2 78.5998H174.4V109.4H176V78.4998H207.5V109.3H209.1V78.4998H215.2V76.8998H209V35.4998H215.3ZM207.6 76.9998H176.1V35.4998H207.6V76.9998Z"
          fill="#38445E"
        />
        <path
          d="M227.8 109.3H160L160.8 65.4002H51L50.5 53.7002H227.8V109.3Z"
          fill="white"
        />
        <path
          d="M58.3999 65.3999V109.3H160L160.8 65.3999H58.3999Z"
          fill="#D7D7D7"
        />
        <path d="M119.2 85H74.5V109.3H119.2V85Z" fill="#55678F" />
        <path d="M74.5 85H119.1V86.6L74.5 87.1V85Z" fill="#38445E" />
        <path d="M116.4 90H76.3999V91H116.4V90Z" fill="#38445E" />
        <path
          d="M116.4 93.1001H76.3999V94.1001H116.4V93.1001Z"
          fill="#38445E"
        />
        <path
          d="M116.4 96.2002H76.3999V97.2002H116.4V96.2002Z"
          fill="#38445E"
        />
        <path d="M116.4 99.3999H76.3999V100.4H116.4V99.3999Z" fill="#38445E" />
        <path d="M116.4 102.5H76.3999V103.5H116.4V102.5Z" fill="#38445E" />
        <path d="M53.8999 109.3V105H160.1L160 109.3H53.8999Z" fill="#38445E" />
        <path d="M74.1001 4.6H156.1V0.4L74.1001 0V4.6Z" fill="#38445E" />
        <path d="M164.7 4.6H217.6V0.4L164.7 0V4.6Z" fill="#38445E" />
        <path
          d="M143.1 15.1001L86.2 15.4001L88 53.6001L143.3 53.7001L143.1 15.1001Z"
          fill="#5B8DC2"
        />
        <path
          d="M86.2 15.4001L143.1 15.1001V20.6001L86.5 20.7001L86.2 15.4001Z"
          fill="#537CAD"
        />
        <path
          opacity="0.5"
          d="M117.2 15.3002L119.9 53.6002H125.5L124.6 15.2002L117.2 15.3002Z"
          fill="#6ECACC"
        />
        <path
          d="M129 71.7002L130.2 100.1H137.9L138.7 71.7002H129Z"
          fill="#537CAD"
        />
        <path
          d="M131.4 100.1L130.6 73.6001H138.6L137.9 100.1H131.4Z"
          fill="#5B8DC2"
        />
        <path
          d="M144.9 71.7002L146.1 100.1H153.9L154.6 71.7002H144.9Z"
          fill="#537CAD"
        />
        <path
          d="M147.3 100.1L146.5 73.6001H154.6L153.9 100.1H147.3Z"
          fill="#5B8DC2"
        />
        <path d="M222.7 88.8999H208.9V109.3H222.7V88.8999Z" fill="#38445E" />
        <path
          d="M211.1 109.3L211.5 91.8999H222.3V109.3H211.1Z"
          fill="#55678F"
        />
        <path d="M213.2 97.7998H212.3V101.2H213.2V97.7998Z" fill="#38445E" />
        <path
          d="M166.3 109.7V89.2998H199.4L199.5 109.7H166.3Z"
          fill="#537CAD"
        />
        <path
          d="M171.4 109.3H199.5L199.4 93.6001H171.4V109.3Z"
          fill="#5B8DC2"
        />
        <path
          opacity="0.5"
          d="M178.2 107.8L183.9 94.3999H188.1L183.4 107.8H178.2Z"
          fill="#6ECACC"
        />
        <path
          opacity="0.5"
          d="M187 107.8L190.7 94.3999H192.8L189.9 107.8H187Z"
          fill="#6ECACC"
        />
        <path
          d="M199.5 109.3L198.4 50.5H212.7L212 109.3H199.5Z"
          fill="#D7D7D7"
        />
        <path
          d="M87.5 42.7002L87.6 45.0002H143.3V42.7002H87.5Z"
          fill="#38445E"
        />
        <path d="M93.8999 43L94.2999 54H95.9999V43H93.8999Z" fill="#38445E" />
        <path d="M112.7 43L113.1 54H114.8V43H112.7Z" fill="#38445E" />
        <path d="M101.6 43L102 54H103.6V43H101.6Z" fill="#38445E" />
        <path d="M120.4 43L120.8 54H122.5V43H120.4Z" fill="#38445E" />
        <path d="M129.2 43L129.6 54H131.3V43H129.2Z" fill="#38445E" />
        <path d="M136 43L136.4 54H138.1V43H136Z" fill="#38445E" />
        <path
          d="M160.8 65.3999H58.3999V68.2999H160.8V65.3999Z"
          fill="#B5B5B5"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="237.994"
          y1="25.6414"
          x2="237.994"
          y2="4.6596"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="66.2368"
          y1="45.1372"
          x2="66.2368"
          y2="30.2236"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="36.4981"
          y1="22.6071"
          x2="36.4981"
          y2="7.69352"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <clipPath id="clip0">
          <rect width="273.7" height="114.1" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const NotFound = () => {
  return (
    <svg
      width={260}
      height={200}
      viewBox="0 0 339 185"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M328.038 184.5c5.44 0 9.879-4.36 9.965-9.801.415-27.116 2.599-67.124-48.47-78.185-41.332-8.955-16.822-82.404-128.135-77.66-79.9 3.405-58.678 70.756-120.8 76.36C-.74 98.95-1.102 151.232.488 175.186c.344 5.236 4.697 9.307 9.95 9.307h317.6v.008z"
        fill="#B5DFEA"
      />
      <path
        opacity={0.55}
        d="M289.532 96.507c-41.331-8.955-16.822-82.404-128.134-77.66-19.006.814-32.283 5.245-42.326 11.593a120.06 120.06 0 0110.803-.916c111.304-4.744 86.803 68.706 128.134 77.661 50.529 10.943 48.933 50.216 48.486 77.308h21.543c5.44 0 9.879-4.36 9.965-9.801.415-27.115 2.598-67.116-48.471-78.185z"
        fill="#90CEDD"
      />
      <path
        d="M240.139 15.722L109.452.466c-5.581-.65-10.638 3.35-11.295 8.931L88.403 92.96c-.65 5.581 3.35 10.638 8.932 11.296l130.686 15.256c5.582.65 10.638-3.35 11.296-8.931l9.754-83.563c.657-5.59-3.343-10.646-8.932-11.296z"
        fill="#D7F0F9"
      />
      <path
        d="M248.108 35.285l.963-8.274c.649-5.581-3.351-10.638-8.932-11.296L109.452.46c-5.581-.65-10.638 3.35-11.295 8.931l-.963 8.274 150.914 17.621z"
        fill="#0582C1"
      />
      <path
        d="M118.346 68.271l16.564-29.19a5.367 5.367 0 015.276-2.677 5.35 5.35 0 014.696 5.933l-3.444 29.527.149.016a3.71 3.71 0 013.256 4.11 3.71 3.71 0 01-4.109 3.256l-.149-.016-.626 5.378a4.004 4.004 0 01-7.953-.924l.626-5.378-11.93-1.393a3.708 3.708 0 01-3.256-4.117l.313-2.709a4.5 4.5 0 01.587-1.816zm15.186 2.184l2.137-18.31c.016-.125-.156-.18-.219-.07l-9.785 17.269a.49.49 0 00.368.728l6.959.814a.49.49 0 00.54-.431zM191.45 76.805l16.564-29.19a5.367 5.367 0 015.276-2.677 5.35 5.35 0 014.696 5.933l-3.444 29.527.149.016a3.71 3.71 0 013.256 4.11 3.71 3.71 0 01-4.109 3.256l-.149-.016-.626 5.378a4.004 4.004 0 01-7.953-.924l.626-5.377-11.93-1.394a3.708 3.708 0 01-3.256-4.117l.313-2.709a4.76 4.76 0 01.587-1.816zm15.194 2.184l2.137-18.31c.016-.125-.157-.18-.219-.07l-9.785 17.269a.49.49 0 00.368.728l6.959.814a.49.49 0 00.54-.43zM158.588 51.097c.963-8.25 5.856-12.454 13.817-11.53 7.953.931 11.749 6.153 10.786 14.395l-3.131 26.811c-.963 8.25-5.855 12.454-13.816 11.53-7.953-.931-11.75-6.152-10.787-14.395l3.131-26.81zm4.909 28.275c-.431 3.679 1.025 5.268 3.608 5.573 2.576.298 4.36-.908 4.791-4.595l3.248-27.843c.431-3.68-1.025-5.269-3.608-5.574-2.576-.297-4.36.908-4.791 4.595l-3.248 27.844z"
        fill="#79C9E8"
      />
      <path
        d="M112.607 12.013a2.738 2.738 0 11-5.44-.634 2.738 2.738 0 115.44.634z"
        fill="#fff"
      />
      <path
        d="M125.711 13.549a2.738 2.738 0 11-5.44-.634 2.738 2.738 0 013.037-2.404 2.726 2.726 0 012.403 3.038z"
        fill="#79C9E8"
      />
      <path
        d="M139.3 15.13a2.738 2.738 0 11-5.44-.635 2.738 2.738 0 013.037-2.403c1.503.18 2.583 1.542 2.403 3.038z"
        fill="#FFBF4D"
      />
      <path
        d="M86.728 110.315s-1.77 7.828-1.472 15.828c.266 7.162-.587 54.255-.587 54.255h5.753s8.188-36.885 8.494-39.797c.305-2.912 4.422-32.039 4.422-32.039l-16.61 1.753z"
        fill="#00668E"
      />
      <path
        d="M90.556 108.171s1.323 10.059 3.577 17.213c2.254 7.147 13.315 55.023 13.315 55.023h5.754s-1.057-36.885-1.456-39.797c-.399-2.912-3.311-32.04-3.311-32.04l-17.88-.399z"
        fill="#0582C1"
      />
      <path
        d="M107.91 180.391v4.015h10.951c.619 0 .869-.806.345-1.15-1.605-1.065-4.165-2.537-6.552-2.865h-4.744zM84.966 180.391v4.015h10.952c.618 0 .868-.806.344-1.15-1.605-1.065-4.165-2.537-6.552-2.865h-4.744z"
        fill="#0582C1"
      />
      <path
        d="M104.716 61.367s3.765-5.723-.556-6.278c-3.319-.43-4.689.563-4.689.563s-3.82-.422-5.918 1.394c-1.432 1.244-6.692 10.88 1.816 14.364 8.509 3.483 6.693-5.44 6.693-5.44l2.654-4.603z"
        fill="#00668E"
      />
      <path
        d="M104.16 55.089c-3.319-.43-4.689.563-4.689.563s-3.82-.422-5.918 1.394c-1.432 1.244-6.692 10.88 1.816 14.364 4 1.636 5.715.532 6.419-1.096-8.07 2.27-8.947-7.46-7.616-10.223 1.354-2.81 4.595-1.519 4.595-1.519 1.268-3.194 5.409-2.85 7.115-2.599-.305-.446-.845-.775-1.722-.884z"
        fill="#05556D"
      />
      <path
        d="M99.417 64.154s-1.12-1.12-1.949 0c-.838 1.12-.282 3.624 1.12 3.765 0 0-.415 3.624-3.21 3.484v2.927h6.552V72.1s4.047-1.676 3.906-5.018c-.141-3.35-1.119-5.723-1.119-5.723s-2.231 2.513-5.3 2.795z"
        fill="#F98D3D"
      />
      <path
        d="M100.426 73.04c.65.274.532.68.564 1.291h.939v-2.23s-1.589.5-3.914-.823c0 .008 1.354 1.323 2.411 1.762z"
        fill="#ED701B"
      />
      <path
        d="M101.765 58.814s-3.397-1.354-3.796-.336c-.258.665.399 1.236 1.307 1.816l-.798.133c-.18.031-.243.258-.102.368.219.18.837.297 2.121.344l1.268-2.325z"
        fill="#F98D3D"
      />
      <path
        d="M126.032 67.324c-6.482-4.799-24.173-8.642-24.173-8.642l-1.456 2.653s16.063 6.223 15.006 7.155c-.626.548-8.47 4.14-11.131 5.354a4.284 4.284 0 01-1.824.376l-8.133-.047s-5.871-.697-10.482 4.603C79.714 83.527 72.7 94.526 72.7 94.526l11.82 17.377 1.308 1.918.007-.023-.007.164s.422.047 1.166.118c3.796.352 15.977 1.315 22.419-.055l.18-.039-.305-28.51c10.286-8.14 15.202-14.434 16.955-16.407a1.213 1.213 0 00-.211-1.745zm-39.656 38.881l-1.151-2.521-3.671-8.023 6.09-6.826-1.096 14.974-.172 2.396z"
        fill="#FFBF4D"
      />
      <path
        d="M85.225 103.685l1.15 2.52-.555 7.601-.016.024-1.307-1.918.728-8.227z"
        fill="#EFAA3A"
      />
      <path
        opacity={0.29}
        d="M106.509 84.46c-1.096.798-.814 7.843-1.088 12.25-.454 7.382-.893 13.769-.572 17.887 1.668-.11 3.218-.29 4.533-.572l.18-.039-.305-28.509c10.285-8.14 15.201-14.434 16.955-16.407a1.19 1.19 0 00-.188-1.746c.008 0-8.126 8.87-19.515 17.136z"
        fill="#F98D2B"
      />
      <path
        d="M111.738 140.608c-.289-2.098-1.886-17.848-2.747-26.459l-4.134.454s6.028 49.574 6.427 65.802h1.91c0-.008-1.057-36.885-1.456-39.797z"
        fill="#00668E"
      />
      <path
        d="M85.232 64.97l-1.244-2.232a8.442 8.442 0 00-7.46-12.384 8.442 8.442 0 00-8.439 8.439 8.442 8.442 0 008.439 8.438c2.348 0 4.47-.962 5.996-2.504l2.708.242z"
        fill="#0582C1"
      />
      <path
        d="M72.137 58.026h.329v1.175c0 .36.11.516.36.516.195 0 .336-.102.516-.32v-1.37h.321v1.925h-.266l-.031-.297h-.016c-.18.21-.368.352-.634.352-.407 0-.587-.258-.587-.752v-1.229h.008zM75.04 60.108v.65h-.329v-2.74h.267l.03.219h.017c.172-.149.39-.266.61-.266.493 0 .76.383.76.978 0 .65-.392 1.041-.83 1.041-.18 0-.36-.086-.533-.219l.008.337zm.47-.384c.313 0 .548-.29.548-.767 0-.423-.141-.712-.509-.712-.164 0-.329.094-.517.258v1.01c.18.156.353.211.478.211zM77.256 59.513c.165.133.337.227.564.227.25 0 .383-.133.383-.298 0-.195-.227-.282-.438-.368-.274-.101-.572-.227-.572-.555 0-.313.243-.548.666-.548.243 0 .454.102.595.219l-.157.203c-.133-.101-.266-.172-.438-.172-.243 0-.352.133-.352.274 0 .18.211.25.43.337.274.102.587.219.587.579 0 .313-.25.572-.704.572-.274 0-.532-.118-.72-.267l.156-.203zM79.535 59.497c.125 0 .234.102.234.25 0 .142-.11.243-.234.243a.233.233 0 01-.235-.242c0-.15.11-.25.235-.25zM80.92 59.497c.125 0 .235.102.235.25 0 .142-.11.243-.235.243a.232.232 0 01-.235-.242.23.23 0 01.235-.25z"
        fill="#fff"
      />
      <path
        d="M52.88 166.441s-1.77-1.659-.58-7.937c1.19-6.278 4.212-12.314 2.725-19.124-1.48-6.818-13.097-23.992-13.128-25.135-.039-1.143-3.585 19.984 2.419 28.313 6.004 8.321 6.724 11.21 6.458 15.586-.266 4.375.063 7.906.72 8.923l1.386-.626z"
        fill="#79C9E8"
      />
      <path
        d="M51.775 153.815c-.696 2.802-1.12 9.824-.008 11.507.102.157.251.071.439-.219-.337-1.159-.564-3.194.078-6.591 1.19-6.278 4.212-12.313 2.724-19.124-1.48-6.818-13.096-23.992-13.127-25.135 0 0 1.753 14.317 7.64 24.165 4.227 7.045 2.951 12.595 2.254 15.397z"
        fill="#49B4D6"
      />
      <path
        d="M54.994 166.01s-3.413-8.517 2.371-15.89c5.785-7.374 15.03-16.846 15.21-20.596 0 0 2.38 17.809-5.15 22.85-7.523 5.041-12.8 5.847-11.5 13.605l-.931.031z"
        fill="#79C9E8"
      />
      <path
        d="M63.118 148.797c-8.391 6.105-9.08 13.143-7.773 16.407.102.25.29.407.548.493-1.127-7.483 4.11-8.337 11.538-13.315 7.523-5.041 5.151-22.85 5.151-22.85s-1.08 13.151-9.464 19.265z"
        fill="#49B4D6"
      />
      <path
        d="M49.38 166.199s1.097-4.509.51-9.378c-.58-4.869-5.668-7.609-10.42-12.752-2.441-2.645-3.17-16.634-1.65-17.933 0 0-8.392 10.168-7.093 17.612 1.292 7.445 14.983 12.282 16.25 15.883 1.269 3.601 1.16 6.615 1.16 6.615l1.244-.047z"
        fill="#79C9E8"
      />
      <path
        d="M34.985 130.135c-2.77 7.507.07 16.603 9.284 22.255 6.779 4.157 4.619 12.807 4.619 12.807l1.111-3.413c.102-1.472.11-3.194-.102-4.963-.579-4.869-5.667-7.609-10.419-12.752-2.356-2.552-3.115-15.656-1.808-17.746a46.305 46.305 0 00-2.685 3.812z"
        fill="#49B4D6"
      />
      <path
        d="M37.67 126.323l.149-.188a.966.966 0 00-.15.188z"
        fill="#49B4D6"
      />
      <path
        d="M56.747 165.635s1.965-7.296 6.834-8.282c4.86-.979 10.45-8.172 10.748-9.582 0 .008-.822 13.558-17.582 17.864z"
        fill="#79C9E8"
      />
      <path
        d="M63.3 159.771c-2.983 1.487-4.22 3.491-4.658 5.315 14.92-4.799 15.687-17.308 15.687-17.308-.063.282-.329.791-.76 1.425-1.463 2.568-5.001 7.937-10.27 10.568z"
        fill="#49B4D6"
      />
      <path
        d="M67.276 162.95H41.835l3.021 18.998a2.968 2.968 0 002.928 2.505H61.32a2.96 2.96 0 002.927-2.505l3.03-18.998z"
        fill="#FFBF4D"
      />
      <path
        d="M66.775 166.097l.5-3.147h-25.44l.5 3.147h24.44z"
        fill="#FF9F50"
      />
      <path
        d="M61.107 162.95l-3.022 18.998a2.968 2.968 0 01-2.927 2.505h6.16a2.96 2.96 0 002.928-2.505l3.022-18.998h-6.161z"
        fill="#FF9F50"
      />
      <path
        d="M242.714 129.071h-114.96v23.374h114.96v-23.374z"
        fill="#FFBF4D"
      />
      <path
        opacity={0.29}
        d="M242.714 129.071h-114.96v5.676h114.96v-5.676z"
        fill="#F98D2B"
      />
      <path
        d="M137.75 129.071h10.497l-2.818 5.676h-10.497l2.818-5.676zM161.39 129.071h10.497l-2.818 5.676H158.58l2.81-5.676zM185.03 129.071h10.49l-2.81 5.676h-10.49l2.81-5.676zM208.671 129.071h10.489l-2.818 5.676H205.86l2.811-5.676zM242.722 129.071v.157l-2.739 5.519h-10.482l2.81-5.676h10.411z"
        fill="#F77E2D"
      />
      <path d="M150.165 152.444h-8.102v31.962h8.102v-31.962z" fill="#79C9E8" />
      <path d="M150.173 152.444h-1.84v31.962h1.84v-31.962z" fill="#49B4D6" />
      <path d="M229.039 152.444h-8.102v31.962h8.102v-31.962z" fill="#79C9E8" />
      <path
        d="M229.039 152.444h-2.395v31.962h2.395v-31.962zM150.165 152.421h-8.102v4.023h8.102v-4.023z"
        fill="#49B4D6"
      />
      <path d="M229.039 152.421h-8.102v4.023h8.102v-4.023z" fill="#49B4D6" />
      <path d="M150.165 125.414h-8.102v3.632h8.102v-3.632z" fill="#79C9E8" />
      <path d="M150.173 125.414h-1.84v3.632h1.84v-3.632z" fill="#49B4D6" />
      <path d="M229.039 125.414h-8.102v3.632h8.102v-3.632z" fill="#79C9E8" />
      <path
        d="M229.039 125.414h-2.395v3.632h2.395v-3.632zM150.165 128.593h-8.102v.454h8.102v-.454z"
        fill="#49B4D6"
      />
      <path d="M229.039 128.593h-8.102v.454h8.102v-.454z" fill="#49B4D6" />
      <path
        d="M148.247 129.069l-11.601 23.374h-8.892v-3.225l9.996-20.149h10.497zM161.391 129.069l-11.593 23.374h10.489l11.601-23.374h-10.497zM195.52 129.069l-11.593 23.374h-10.489l11.593-23.374h10.489zM219.16 129.069l-11.593 23.374h-10.489l11.593-23.374h10.489zM232.311 129.069l-11.593 23.374h10.49l11.514-23.217-10.411-.157z"
        fill="#0582C1"
      />
      <path
        d="M148.247 129.069l-2.818 5.676h-10.497l2.818-5.676h10.497zM171.887 129.069l-2.818 5.676H158.58l2.81-5.676h10.497zM195.52 129.069l-2.81 5.676h-10.49l2.81-5.676h10.49zM219.16 129.069l-2.818 5.676H205.86l2.811-5.676h10.489zM242.722 129.226l-2.74 5.519H229.5l2.811-5.676 10.411.157z"
        fill="#00668E"
      />
      <path
        opacity={0.55}
        d="M240.734 139.185H130.956v11.694h109.778v-11.694z"
        fill="url(#prefix__paint0_linear)"
      />
      <path
        d="M147.054 127.478a7.225 7.225 0 10-2.32-14.262 7.225 7.225 0 002.32 14.262z"
        fill="#FFBF4D"
      />
      <path
        d="M149.179 120.342a3.27 3.27 0 00-3.272-3.272 3.27 3.27 0 00-3.272 3.272 3.27 3.27 0 003.272 3.272 3.265 3.265 0 003.272-3.272z"
        fill="#0582C1"
      />
      <path
        d="M226.084 127.481a7.225 7.225 0 10-2.321-14.262 7.225 7.225 0 102.321 14.262z"
        fill="#FFBF4D"
      />
      <path
        d="M228.21 120.342a3.27 3.27 0 00-3.272-3.272 3.27 3.27 0 00-3.272 3.272 3.27 3.27 0 003.272 3.272 3.27 3.27 0 003.272-3.272z"
        fill="#0582C1"
      />
      <path
        d="M286.277 181.924h-36.611l5.596-13.573v-.008l2.255-5.48.618-1.503 1.01-2.442.204-.501 2.254-5.479.415-.994 3.992-9.691c.72-1.746 3.194-1.746 3.914 0l3.992 9.691.415.994 2.255 5.479.203.501 1.01 2.442.618 1.503 2.255 5.48v.008l5.605 13.573z"
        fill="#FFBF4D"
      />
      <path
        opacity={0.29}
        d="M252.06 176.109l-2.403 5.816h36.619l-2.396-5.816h-31.82z"
        fill="#F98D2B"
      />
      <path d="M288.664 178.447H246.73v5.926h41.934v-5.926z" fill="#79C9E8" />
      <path d="M288.664 178.447h-9.057v5.926h9.057v-5.926z" fill="#49B4D6" />
      <path
        opacity={0.29}
        d="M284.836 178.448h-5.182l-4.157-10.09v-.008l-2.254-5.48-.618-1.503-1.01-2.442-.204-.501-2.254-5.48-.415-.994-3.358-8.156.626-1.535c.72-1.745 3.194-1.745 3.914 0l3.992 9.691.415.994 2.254 5.48.204.501 1.01 2.442.618 1.503 2.255 5.48v.008l4.164 10.09z"
        fill="#F98D2B"
      />
      <path
        d="M276.578 158.428h-17.229l2.255-5.48h12.728l2.246 5.48zM280.672 168.346v.008h-25.409v-.008l2.246-5.48h20.909l2.254 5.48z"
        fill="#0582C1"
      />
      <path
        d="M276.578 158.428h-5.174l-2.255-5.48h5.182l2.247 5.48zM280.672 168.346v.008h-5.182v-.008l-2.247-5.48h5.174l2.255 5.48z"
        fill="#00668E"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={185.848}
          y1={159.281}
          x2={185.848}
          y2={140.451}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const TimerIcon = () => {
  return (
    <svg
      width={181}
      height={191}
      viewBox="0 0 181 191"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M143.367 89.645c.028 17.498-6.999 34.331-19.616 46.994-12.982 13.058-31.315 21.202-51.635 21.202-39.354 0-71.258-30.53-71.258-68.19-.024-17.304 6.85-33.964 19.22-46.585C33.085 29.77 51.585 21.454 72.116 21.454c39.342.002 71.251 30.531 71.251 68.191z"
        fill="#2B478B"
      />
      <path
        d="M143.368 89.646c.027 17.497-7 34.33-19.617 46.994L20.071 43.06C33.078 29.767 51.578 21.45 72.11 21.45c39.348.006 71.258 30.536 71.258 68.196z"
        fill="#8C9DF4"
      />
      <path
        d="M64.6 33.2l-2.455.658-.506-1.863 3.66-1.117h1.673v13.131h-2.374L64.6 33.2zM69.798 42.205l4.69-3.819c1.87-1.505 2.55-2.347 2.55-3.52 0-1.285-.953-2.03-2.2-2.03-1.246 0-2.1.652-3.144 1.955L70 33.525c1.303-1.751 2.588-2.736 5.021-2.736 2.667 0 4.497 1.565 4.497 3.931 0 2.052-1.144 3.146-3.543 5.047l-2.803 2.235h6.482v2.006h-9.849l-.007-1.803zM67.063 144.62c-1.052-1.006-1.673-2.365-1.673-5.028 0-3.987 1.985-7.098 5.839-7.098 1.77 0 2.977.547 4.165 1.454l-1.287 1.769c-.953-.707-1.77-1.136-2.958-1.136-2.024 0-3.154 1.751-3.27 4.135.715-.671 1.654-1.305 3.251-1.305 2.646 0 4.768 1.491 4.768 4.135 0 2.59-2.2 4.433-5.061 4.433-1.672 0-2.879-.502-3.774-1.359zm6.443-3.018c0-1.303-1.091-2.272-2.783-2.272-1.693 0-2.765 1.044-2.765 2.326 0 1.322 1.11 2.347 2.823 2.347 1.693.003 2.725-1.022 2.725-2.401zM118.428 93.624l1.674-1.49c.973 1.095 2.063 1.715 3.582 1.715 1.342 0 2.354-.783 2.354-2.013 0-1.285-1.226-2.012-3.133-2.012h-1.109l-.39-1.433 3.679-3.744h-5.898v-1.993h9.005v1.714l-3.795 3.725c2.16.298 4.002 1.36 4.002 3.707 0 2.401-1.946 4.115-4.768 4.115-2.361 0-4.055-.949-5.203-2.29zM15.525 94.464l1.305-1.751c1.031.838 1.946 1.23 3.114 1.23 2 0 3.211-1.694 3.287-4.102-.682.782-1.732 1.378-3.193 1.378-2.899 0-4.827-1.62-4.827-4.173 0-2.552 2.064-4.514 5.08-4.514 1.715 0 2.823.465 3.776 1.379 1.012.968 1.655 2.402 1.655 5.01 0 4.191-2.18 7.096-5.838 7.096-1.886.011-3.21-.641-4.359-1.553zm7.63-7.544c0-1.36-1.109-2.402-2.841-2.402-1.694 0-2.706 1.08-2.706 2.462 0 1.383 1.09 2.326 2.784 2.326 1.731-.002 2.763-1.082 2.763-2.386z"
        fill="#EBF3FA"
      />
      <path
        d="M72.246 158.866c-17.92-.022-35.193-6.413-48.468-17.932C10.502 129.414 2.17 113.586.4 96.521c-1.77-17.065 3.145-34.15 13.794-47.944 10.65-13.793 26.272-23.309 43.838-26.703 17.566-3.393 35.823-.423 51.23 8.336 15.407 8.759 26.865 22.681 32.152 39.067 5.287 16.386 4.026 34.067-3.539 49.613-7.565 15.546-20.895 27.849-37.402 34.523a74.87 74.87 0 01-28.228 5.453zm0-136.035c-17.206.023-33.791 6.16-46.537 17.221C12.963 51.114 4.965 66.312 3.266 82.697c-1.7 16.385 3.021 32.791 13.247 46.034 10.225 13.243 25.226 22.38 42.093 25.638 16.866 3.258 34.396.405 49.189-8.006 14.793-8.41 25.795-21.778 30.87-37.512 5.076-15.733 3.865-32.71-3.399-47.636-7.264-14.927-20.063-26.74-35.913-33.148a71.905 71.905 0 00-27.106-5.236z"
        fill="#172A49"
      />
      <path d="M81.253 15.878H62.327v6.087h18.926v-6.087z" fill="#172A49" />
      <path d="M73.311 10.126h-3.044v6.906h3.044v-6.906z" fill="#172A49" />
      <path
        d="M71.79 11.771a6.082 6.082 0 01-3.274-.95 5.7 5.7 0 01-2.17-2.53 5.413 5.413 0 01-.335-3.258 5.567 5.567 0 011.613-2.887A5.979 5.979 0 0170.64.604a6.138 6.138 0 013.404.321 5.844 5.844 0 012.645 2.077c.647.927.993 2.017.993 3.132a5.523 5.523 0 01-1.728 3.985 6.034 6.034 0 01-4.164 1.653zm0-8.848c-.664 0-1.312.188-1.864.541a3.245 3.245 0 00-1.236 1.44 3.082 3.082 0 00-.19 1.856 3.17 3.17 0 00.918 1.643c.469.45 1.067.755 1.717.879.651.124 1.326.06 1.939-.183a3.328 3.328 0 001.505-1.182 3.113 3.113 0 00.566-1.784 3.146 3.146 0 00-.984-2.269 3.437 3.437 0 00-2.371-.941z"
        fill="#172A49"
      />
      <path
        d="M107.005 90.148c.001 1.937-.18 3.87-.541 5.775a31.293 31.293 0 01-4.904 11.727 32.804 32.804 0 01-9.257 9.005 34.346 34.346 0 01-12.172 4.884c-4.356.84-8.843.842-13.2.007a34.344 34.344 0 01-12.178-4.872 32.794 32.794 0 01-9.266-8.996 31.286 31.286 0 01-4.917-11.722c-.81-4.18-.744-8.474.196-12.63 0-.025.011-.049.016-.075 1.776-7.672 6.426-14.456 13.072-19.071.69-.478 1.398-.93 2.126-1.358 5.27-3.102 11.338-4.739 17.525-4.726.858 0 1.702.03 2.533.091 5.96.422 11.691 2.372 16.592 5.644a32.789 32.789 0 019.189 9.174c.514.775.993 1.572 1.438 2.39a30.812 30.812 0 013.748 14.752z"
        fill="#F99746"
      />
      <path
        d="M62.125 75.797c-1.336 2.026.558 5.544-3.453 4.585-4.01-.96-6.46-1.387-6.684.744-.223 2.13 3.002 2.134 3.002 4.377s-1.448 2.979-3.675 1.915-2.87-2.61-5.46-1.65-4.094.32-4.678-1.599c-.09-.3-.23-.584-.416-.84 0-.026.011-.049.015-.075 1.777-7.674 6.43-14.46 13.08-19.075.688-.478 1.396-.931 2.124-1.359 1.045.037 2.288.41 3.358 1.676 2.34 2.773 3.9 1.387 3.342 4.585-.557 3.198.334 3.625 2.228 3.518 1.894-.107 4.123-.108 4.344 1.279.222 1.387-5.79-.107-7.127 1.919zM80.648 93.01s-2.332.715-2.948-.843c-.616-1.558.089-1.893-1.978-2.355-2.067-.462-2.243-1.81-3.56-1.641-1.318.168-.66.673-2.199 1.767-1.538 1.094-2.947.59-2.903 2.608.044 2.02 2.331 2.335 2.793 3.66.462 1.326-.33 1.895.659 2.904.99 1.01 2.836.568 1.978 1.957-.857 1.388-2.175 2.462-1.516 4.608.659 2.146.197 2.272 1.319 3.786 1.122 1.514 3.297 2.02 3.628 1.011.33-1.01-1.518-2.337-.924-3.346.595-1.009 1.583-.316 1.979-1.83.395-1.514 2-1.516 2.132-2.568.132-1.051-1.935-2.65-1.143-3.323s2.374 1.22 3.606-.463c1.232-1.682.395-3.03 1.67-4.628 1.275-1.6-1.714-2.23-2.593-1.304zM107.006 90.146a30.934 30.934 0 01-.542 5.775 31.125 31.125 0 01-5.288 12.298 3.982 3.982 0 01-2.679-.069c-4.177-1.599-1.086-5.917.585-8.07 1.671-2.153-2.59-1.2-5.68-3.198s.332-7.356-2.842-6.476c-3.175.88-7.018 1.998-8.862-.958-1.844-2.956 1.67-3.438.083-5.917-1.588-2.479.919-3.917 4.095-4.158 3.176-.24 2.757-2.398 6.683-2.718 3.927-.32 3.844 4.477 8.189 4.158 4.345-.319 4.145.639 4.145.639.314-.007.624.055.908.182a30.807 30.807 0 011.205 8.512zM103.258 75.396c-3.614 1.682-5.063-2.892-8.147-1.517-3.114 1.387-2.573-1.279-5.019-2.986-2.445-1.707-7.353 1.387-7.13-1.279.223-2.666 3.898-1.915 4.679-4.05.78-2.135 4.345-1.6 4.345-1.6.209-.073.427-.12.649-.136a32.794 32.794 0 019.185 9.177c.517.776.997 1.573 1.438 2.39zM72.82 66.203s1.893-2.615-1.114-3.385c-3.007-.77-2.953.238-5.289 0-2.335-.238-3.259 1.493-1.505 3.385 1.754 1.892 3.176 5.523 4.763 3.588 1.587-1.936 1.28-3.4 3.145-3.588z"
        fill="#ED7F35"
      />
      <path
        d="M72.397 91.825l-.491.008a1.55 1.55 0 01-.582-.088 1.502 1.502 0 01-.5-.298 1.428 1.428 0 01-.34-.46 1.373 1.373 0 01-.129-.55l.229-41.08a.85.85 0 01.277-.599.928.928 0 01.635-.243.93.93 0 01.647.226.853.853 0 01.295.595l1.376 41.137c.001.178-.035.355-.106.52-.07.164-.175.314-.307.44a1.412 1.412 0 01-.46.292 1.46 1.46 0 01-.544.1z"
        fill="#172A49"
      />
      <path
        d="M72.19 91.83l-.052-.035c-.092-.06.23-.665.714-1.34l25.473-35.57c.286-.399.556-.685.61-.653.055.034-.13.386-.416.786L73.17 90.773c-.447.636-.893 1.108-.978 1.056z"
        fill="#233862"
      />
      <path
        d="M69.458 90.62l.174-.44c.302-.766 1.307-1.107 2.227-.757l48.486 18.465c.541.207.833.733.656 1.185-.179.454-.766.664-1.318.473l-49.15-17.065c-.875-.303-1.358-1.14-1.075-1.86z"
        fill="#172A49"
      />
      <path
        d="M64.584 90.265a6.943 6.943 0 011.194-4.002 7.415 7.415 0 013.316-2.693 7.8 7.8 0 014.32-.476 7.614 7.614 0 013.87 1.901 7.092 7.092 0 012.112 3.638 6.88 6.88 0 01-.354 4.148 7.232 7.232 0 01-2.703 3.26 7.723 7.723 0 01-4.14 1.274c-1.987.031-3.905-.694-5.333-2.016-1.428-1.322-2.249-3.133-2.282-5.034z"
        fill="#2B478B"
      />
      <path
        d="M172.492 131.819s.48.809.169 1.68c-.777 2.171-.757 3.009.596 3.978 0 0 1.869.89 1.625-.11a3.476 3.476 0 01.123-1.797c.571-1.577.591-2.347-.279-3.461l-.376-.563-1.858.273z"
        fill="#FFB27D"
      />
      <path
        d="M173.111 133.399s-.411.069-.536.351c-.402.905-1.329 1.689-1.193 1.832.285.299.929-.284 1.316-.856.246-.404.387-.859.413-1.327z"
        fill="#FFB27D"
      />
      <path
        d="M151.879 108.185c-.27-3.294 3.469-5.552 6.422-3.84l.18.108c4.421 2.724 13.959 15.428 14.615 16.702.657 1.273 2.593 11.398 2.593 11.398s-2.394.837-3.513-.032c0 0-3.884-9.603-4.457-10.083-.573-.481-15.531-8.511-15.658-11.522a75.797 75.797 0 00-.182-2.731z"
        fill="#EF7E29"
      />
      <path
        d="M178.571 182.55s1.783 1.555 1.43 2.662c-.354 1.106-1.956 1.844-2.173 3.195-.217 1.352-1.088 2.089-1.87 2.089h-3.73s-.572-.958.814-1.231c1.387-.274 1.916-1.876 1.83-3.35-.086-1.475.13-3.44.13-3.44l2.956-.657.613.732zM156.705 186.574s.32 3.542-.462 3.911l-10.014-.021s0-1.105 1.608-1.351 4.431-1.146 4.913-3.154c.481-2.008 3.955.615 3.955.615z"
        fill="#2A4A7C"
      />
      <path
        d="M148.25 136.538s.529 6.208 1.143 13.678c.845 10.138 1.859 22.592 1.96 24.952.173 4.103.869 10.649.869 10.649s1.651 1.558 4.956.902c0 0 2.944-9.355 3.786-19.254l.039-.488c.783-10.077 2.001-31.294 2.001-31.294l-14.754.855z"
        fill="#8C9DF4"
      />
      <path
        d="M148.25 136.538s.529 6.208 1.143 13.678l11.571 17.249.039-.488c.783-10.077 2.001-31.294 2.001-31.294l-14.754.855z"
        fill="#707FE5"
      />
      <path
        d="M150.663 137.275s9.519 22.487 15.78 33.055c6.26 10.568 8.216 13.517 8.216 13.517s3.586-1.352 3.912-2.274c0 0-2.394-20.401-6.587-25.374-2.368-2.811-4.378-10.86-4.708-13.794-.384-3.42-1.421-9.185-1.421-9.185l-15.192 4.055z"
        fill="#8C9DF4"
      />
      <path
        d="M158.872 103.542s-6.38 3.097-10.115 2.064a8.22 8.22 0 011.603-1.436c.255-.181.453-.427.571-.709.118-.283.152-.591.098-.891a15.31 15.31 0 01-.122-.787 16.495 16.495 0 01-.197-1.894l2.445-1.915 2.32-1.834c-.081 5.032 3.397 7.402 3.397 7.402z"
        fill="#FFB27D"
      />
      <path
        d="M165.202 134.998c-2.573 1.491-5.013 2.613-7.861 3.243-3.366.764-7.503.538-8.887.74a.59.59 0 01-.403-.097c-.108-.053-.714-3.982-1.33-8.94a7.67 7.67 0 01-.068-.603l-.01-.067a183.052 183.052 0 01-.627-6.019 143.027 143.027 0 01-.353-4.634c-.106-1.845-.15-3.541-.106-4.935.02-.637.059-1.21.117-1.708.366-2.897 1.81-4.967 3.169-6.507l1.338-1.219c1.308.08 2.43-.167 3.692-.957 1.377-.867 2.859-1.8 3.145-1.641 2.111 1.105 3.83 3.966 5.058 7.751a41.638 41.638 0 011.59 7.264c.111.812.205 1.642.277 2.475l.039.458c.165 2.267.436 4.392.736 6.292.404 2.561.857 4.704 1.187 6.214.163.769.286 1.368.361 1.788.065.364-.712.896-1.064 1.102z"
        fill="#AEC3FF"
      />
      <path
        d="M165.23 135.006c-2.573 1.492-5.013 2.614-7.862 3.243-3.366.763-7.503.539-8.886.74a.59.59 0 01-.403-.097c-.109-.053-.715-3.982-1.331-8.94-.027-.2-.056-.401-.069-.603l-.008-.067a183.748 183.748 0 01-.628-6.019 145.918 145.918 0 01-.353-4.634c-.106-1.845-.15-3.54-.106-4.935.02-.637.059-1.211.118-1.708.366-2.896 1.689-5.143 3.136-6.61.316-.321 1.37-1.115 1.37-1.115 1.308.08 2.43-.168 3.692-.957 1.377-.869 2.859-1.801 3.145-1.641 2.111 1.105 3.831 3.966 5.059 7.751a41.626 41.626 0 011.589 7.265c.11.812.204 1.641.277 2.474.015.153.026.306.039.458.166 2.268.429 4.392.736 6.292.405 2.561.858 4.704 1.188 6.214.163.769.286 1.368.36 1.789.06.361-.716.893-1.063 1.1z"
        fill="#F99746"
      />
      <path
        d="M155.558 98.06s-1.572 2.873-4.651 3.724a16.275 16.275 0 01-.198-1.894l2.446-1.915 2.403.086z"
        fill="#ED985F"
      />
      <path
        d="M149.575 99.829s6.164.844 6.665-2.781c.502-3.625 1.637-5.958-2.278-6.65-3.915-.692-4.871.596-5.352 1.768-.48 1.172-.624 7.35.965 7.663z"
        fill="#FFB27D"
      />
      <path
        d="M158.325 89.882c.055-.064.183-.115.469-.137a1.198 1.198 0 00-.537-.137c-.069-.38-.295-1.05-.982-1.201.113.06.21.144.284.245a.806.806 0 01.145.339c-.809-.765-2.522-1.3-7.223-.855-6.195.587-4.071 4.35-3.259 4.788.812.437 2.81-.843 4.308-.758 1.498.085 1.827 2.15 2.341 2.424.515.273.303-.225.984-.534.68-.31.769.777.586 1.49-.183.712.779 1.629.779 1.629l.933-1.001c.934-1.002 1.747-5.183 1.294-6.063a6.732 6.732 0 01-.122-.23z"
        fill="#233862"
      />
      <path
        d="M156.369 114.012s-5.197 3.784-10.706 4.613c-.106-1.846-.15-3.542-.106-4.936l10.812.323z"
        fill="#EF7E29"
      />
      <path
        d="M124.454 109.13c-.081-.085-1.01-2.873-2.859-3.031-1.475-.137-4.735.547-3.776 1.043.32.167 2.107 1.219 3.025 2.736.454.754 1.848.986 1.848.986l1.762-1.734z"
        fill="#FFB27D"
      />
      <path
        d="M157.021 106.706a4.005 4.005 0 01-.448 7.236l-.194.078c-4.881 1.872-19.011 2.627-22.698 1.735-1.438-.347-11.218-4.576-11.218-4.576s.673-2.354 2.062-2.724c0 0 8.903 1.156 10.636 1.368 8.96 1.112 16.787-6.206 19.428-4.562.698.435 1.553.941 2.432 1.445z"
        fill="#F99746"
      />
      <path
        d="M120.274 59.98a.697.697 0 01-.503-.334c-3.27-5.512-9.462-12.975-20.39-17.222a.687.687 0 01-.363-.364.646.646 0 01.001-.503c.069-.16.2-.29.366-.361a.733.733 0 01.525-.018c11.301 4.392 17.703 12.106 21.083 17.805a.646.646 0 01-.032.712.697.697 0 01-.298.236.733.733 0 01-.384.049h-.005z"
        fill="#2B478B"
      />
      <path
        d="M122.827 64.303l-.531-8.37-2.152 3.036-3.82-.285 6.503 5.62z"
        fill="#2B478B"
      />
      <path
        d="M24.492 116.925a.694.694 0 01.505.333c3.27 5.512 9.462 12.976 20.388 17.223a.709.709 0 01.403.359.649.649 0 01-.147.751.712.712 0 01-.511.196.716.716 0 01-.274-.06c-11.3-4.393-17.703-12.107-21.083-17.806a.646.646 0 01.032-.712.692.692 0 01.298-.235.73.73 0 01.384-.049h.005z"
        fill="#8C9DF4"
      />
      <path
        d="M21.94 112.602l.53 8.369 2.153-3.035 3.82.285-6.503-5.619z"
        fill="#8C9DF4"
      />
    </svg>
  );
};
