import { TicketBorder } from "components";
import React from "react";
import { Expired } from "components/IndexIcon";
import Head from "components/Head";

const styles = {
  image: {
    display: "flex",
    justifyContent: "center",
    margin: "50px auto",
  },
  image_min: {
    width: 165,
    height: 150,
  },
  content: {
    fontFamily: "Montserrat",
    lineHeight: "24px",
    fontWeight: "bold",
    fontSize: 18,
    textAlign: "center",
  },
};

export const ExpiredPage = (guest) => {
  return (
    <React.Fragment>
      <Head subHeading={"Building Management System"} />
      <div className="main-container" style={{ padding: "0px" }}>
        <div style={{ padding: "28px 12px" }}>
          <Expired />
          <h1 style={{ ...styles.content, fontSize: 20, paddingTop: "30px" }}>
            Error: {guest === "visitor" ? "Contractor" : "Invite"} Expired
          </h1>
          <h1
            style={{ ...styles.content, fontWeight: 100, margin: "10px 20px" }}
          >
            Contact Condo admin
          </h1>
        </div>

        <TicketBorder />
      </div>
    </React.Fragment>
  );
};
export default ExpiredPage;
