import React from "react";
import Head from "components/Head";
import { HomeIcon } from "components/IndexIcon";

const styles = {
  homeicon: {
    display: "flex",
    justifyContent: "center",
    marginTop: "50px",
  },
  fontstyle: {
    textAlign: "center",
    color: "rgb(50,129,124)",
    fontSize: "25px",
    marginTop: "40px",
  },
};

const Home = () => {
  return (
    <div>
      <Head subHeading={"Building Management System"} />
        <div className="main-container">
          <div style={styles.homeicon}>
            <HomeIcon style={{ marginTop: "20px" }} />
          </div>
          <h1 style={styles.fontstyle}>
            Welcome to the HDB Smart Building System
          </h1>
        </div>
      </div>
  );
};

export default Home;
