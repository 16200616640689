import React, { Component } from "react";
import moment from "moment";
import { QRBlock, TimerBlockIcon, QRContent } from "components/QRComponent";

export default class LeftCard extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.state = {
      data: {},
      showQR: false,
      valid_till: "",
    };
  }
  componentDidMount = () => {
    const { data } = this.props;
    let validateTime = moment(data.created_at).isBefore(moment().format());
    this.setState({
      ...data,
      showQR: validateTime ? true : false,
    });
  };

  showQRPage = () => {
    this.setState({ showQR: true });
    this.props.showQRPage();
  };
  render() {
    const styles = {
      nameBlock: { padding: "0px 20px 50px", position: "relative" },
      name: {
        fontFamily: "Montserrat",
        fontWeight: 300,
        fontSize: "18px",
        textAlign: "center",
        color: "#000",
      },
    };
    const { showQR } = this.state;
    const { valid_till, created_at, id } = this.props.data;
    var decoded_json = {
      id: id,
      type: "Entry_visitor",
    };
    var stringified_json = JSON.stringify(decoded_json);
    var encoded_data = window.btoa(stringified_json);
    let qrContent = {
      showQR,
      created_at,
      valid_till,
      showQRPage: this.showQRPage,
      invite: true,
    };
    return (
      <div>
        {showQR ? <QRBlock value={encoded_data} /> : <TimerBlockIcon />}
        <h4
          style={{
            ...styles.name,
            fontSize: 18,
            fontWeight: 400,
            textTransform: "capitalize",
          }}
        >
          Dear <b>{this.props.data.visitor.name},</b>
        </h4>
        <QRContent {...qrContent} />

        <div
          style={{
            textAlign: "center",
            marginBottom: 20,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        ></div>
      </div>
    );
  }
}
