import React from "react";
import { TimerIcon } from "assets/icons/indexIcon";

const TimerBlockIcon = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ marginTop: "30px", paddingLeft: "15px" }}>
        <TimerIcon />
      </div>
    </div>
  );
};

export default TimerBlockIcon;
