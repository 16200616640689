import React, { useState } from "react";
import { InputAdornment } from "@material-ui/core";
import {
  SimpleTextField,
  DateTimeField,
  Head,
  VisitorTypes,
  Toast,
} from "components";
import SubmitButton from "components/SubmitButton";
import { Calender } from "assets/icons";
import { CountryPhoneField } from "components/FormField";

const styles = {
  input: {
    width: 365,
    marginBottom: 15,
  },
  text: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: 16,
    lineHeight: "20px",
    color: "#404040",
  },
  heading: {
    fontSize: 22,
    fontFamily: "Montserrat",
    color: "#000",
  },
  types: {
    marginTop: "15px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  },
};

const CalendarIcon = () => {
  return (
    <InputAdornment position="end">
      <Calender />
    </InputAdornment>
  );
};
const InvitePage = ({
  data,
  validation,
  inviteSubmit,
  disableBtn,
  handleInviteeChange,
  handlePurposeChange,
  handleInviteCustomChange,
  validationErrors,
  handleVisitingTime,
  handleChangeNric,
}) => {
  const {
    name,
    phone,
    country_code,
    employer,
    visitor_type,
    visiting_time,
    remarks,
    fin_number,
  } = data;
  const [validationError, setValidationError] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const dateValidationHandler = (bln_time_diff, str_helper_txt, dt_date) => {
    if (!bln_time_diff) {
      if (str_helper_txt !== "") {
        setValidationError(str_helper_txt);
      }
    } else {
      setValidationError("Date selected");
      setSelectedDate(dt_date);
      handleVisitingTime(dt_date);
    }
  };

  return (
    <React.Fragment>
      <Head subHeading={"Smart QR Invite"} />
      <div className="main-container">
        <div style={{ textAlign: "center", width: "100%" }}>
          <p id="head-text" style={{ fontWeight: 500, fontSize: 20 }}>
            Enter the visitor details
          </p>
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            inviteSubmit();
          }}
          className="forminvite"
        >
          <div style={styles.types}>
            <VisitorTypes
              visitor_type={visitor_type}
              onChange={handlePurposeChange}
            />
          </div>
          <div style={{ paddingTop: "30px" }}>
            <DateTimeField
              label={"Visiting Time"}
              value={selectedDate || visiting_time}
              InputProps={{
                endAdornment: CalendarIcon(),
                style: { ...styles.text },
              }}
              className="a1-date-picker"
              helperText={
                validationError ||
                validation.dateErrorText ||
                "Please Select Date and Time"
              }
              onChange={dateValidationHandler}
            />
            <div style={{ marginBottom: 0 }}>
              <p style={styles.heading}>Visitor Details</p>
              <SimpleTextField
                required
                id="name"
                name="name"
                label="Name"
                value={name}
                onChange={handleInviteeChange}
                helperText=" "
                autoComplete="off"
              />
              <CountryPhoneField
                selected={country_code}
                onSelect={handleInviteeChange}
                required
                name="phone"
                label="Phone"
                value={phone}
                onChange={handleInviteCustomChange}
                error={validation.phone}
              />

              <SimpleTextField
                label="NRIC/FIN (Last 4 digits)"
                name="fin_number"
                id="fin_number"
                required
                value={fin_number}
                onChange={(e) => handleChangeNric(e.target.value)}
                helperText=" "
                autoComplete="off"
              />

              <SimpleTextField
                id="employer"
                name="employer"
                label="Employer"
                value={employer}
                onChange={handleInviteeChange}
                helperText=" "
                autoComplete="off"
              />
              <SimpleTextField
                id="remarks"
                name="remarks"
                label={"Notes"}
                defaultValue={remarks}
                multiline
                onChange={handleInviteeChange}
                style={{
                  ...styles.input,
                  width: "100%",
                }}
                inputProps={{
                  style: { ...styles.text, minHeight: 40 },
                }}
              />
              {validationErrors && <Toast msg={validationErrors} />}
            </div>
          </div>
          <div style={{ textAlign: "center" }}>
            <SubmitButton loading={disableBtn} />
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};
export default InvitePage;
