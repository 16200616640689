import React from "react";
import { withStyles } from "@material-ui/styles";
import SubmitButton from "components/SubmitButton";

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

const RightCard = (props) => {
  const {
    data: { name, invite },
  } = props;

  var { invitor } = invite || {};
  invitor = invitor || {};
  return (
    <div className="qr-innercontainer1">
      <p className="para-head">
        Dear <span style={{ textTransform: "capitalize" }}>{name},</span>
      </p>
      <p className="para" style={{ color: "#77838F", margin: "0px 15px 10px" }}>
        {"You have been invited by"}
        <b style={{ textTransform: "capitalize" }}> {invitor.name}</b> to
        his/her office at the below address.
      </p>
      <div style={{ ...styles.container, padding: "30px 0px 38px 0px" }}>
        <SubmitButton
          onClick={(e) => {
            e.preventDefault();
            window.open("https://goo.gl/maps/1SAekbtfeGmYuiv48");
          }}
          text="Get Direction"
        />
      </div>
    </div>
  );
};

export default withStyles(styles)(RightCard);
