import React from "react";
import { Button } from "@material-ui/core";
import "assets/css/common.css";

const SubmitButton = ({ onClick, loading, text, cursor }) => {
  return (
    <Button
      className="button"
      variant="contained"
      type="submit"
      onClick={onClick}
      disabled={loading}
      style={{ backgroundColor: "#451BAB", cursor: cursor }}
    >
      {text || "SUBMIT"}
    </Button>
  );
};
export default SubmitButton;
