import React from "react";

const TicketBorder = () => {
  return (
    <span
      style={{
        backgroundImage: `url(${require("../assets/img/base_border.svg")})`,
        backgroundRepeat: "repeat-x",
        width: "100%",
        display: "block",
        height: 15,
        left: 0,
        marginBottom: "-10px",
      }}
    />
  );
};

export default TicketBorder;
