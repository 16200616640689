import React from "react";
import QRCode from "qrcode.react";

const QRBlock = ({ value }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <QRCode
        value={value}
        renderAs="svg"
        size={220}
        style={{
          padding: "20px 10px",
        }}
      />
    </div>
  );
};

export default QRBlock;
