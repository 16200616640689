import { apiHost } from "../config";
import { jsonHeaders } from "../helpers/index";

export default function({ uri, method = "GET", body, authorization }) {
  return fetch(apiHost + uri, {
    method,
    body,
    headers: authorization
      ? Object.assign(jsonHeaders, {
          Authorization: `API_KEY ${authorization}`,
        })
      : jsonHeaders,
  }).then(async (response) => {
    if (response.ok) {
      try {
        return response.json();
      } catch (err) {
        return true;
      }
    }
    const errMsg = [response.status, await response.json()];
    throw errMsg;
  });
}
