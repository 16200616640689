import React from "react";
import { Guest, Contractor } from "assets/icons/purpose";

const styles = {
  container: {
    marginTop: "15px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  purposeText: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "20px",
    textAlign: "center",
    color: "#451BAB",
    margin: 0,
  },
  iconBlock: {
    padding: 6,
    boxSizing: "border-box",
    width: "50%",
    maxWidth: 150,
    height: 150,
  },
};

const VisitorTypes = ({ visitor_type, onChange }) => {
  const visitor_types = [
    {
      img: <Guest />,
      name: "Visitor",
      value: "guest",
    },
    {
      img: <Contractor />,
      name: "Contractor",
      value: "contractor",
    },
  ];
  const handleChange = (value) => {
    onChange({
      target: {
        name: "visitor_type",
        value: value,
      },
    });
  };
  return (
    <div style={styles.container}>
      {visitor_types.map((purposeVal, i) => {
        const selected = purposeVal.value === visitor_type;
        return (
          <div
            key={i}
            style={styles.iconBlock}
            onClick={() => handleChange(purposeVal.value)}
          >
            <div
              className={"invite-icon"}
              style={{
                border: selected ? "3px solid #451BAB" : "1px solid lightgray",
              }}
            >
              <div style={{ maxHeight: 80, minHeight: 75 }}>
                {purposeVal.img}
              </div>
              <p style={styles.purposeText}>{purposeVal.name}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default VisitorTypes;
