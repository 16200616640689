import React, { Component } from "react";
import { QRBlock, TimerBlockIcon, QRContent } from "components/QRComponent";
import moment from "moment";
export default class TopCard extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.state = {
      showQR: false,
    };
  }

  componentDidMount = () => {
    const { data } = this.props;
    let validateTime = moment(data.invite.visiting_time).isBefore(
      moment().format()
    );
    this.setState({
      ...data,
      showQR: validateTime ? true : false,
    });
  };

  showQRPage = () => {
    this.setState({ showQR: true });
    this.props.showQRPage();
  };
  render() {
    const { showQR } = this.state;
    const {
      data: {
        id,
        valid_till,
        invite: { visiting_time },
      },
    } = this.props;

    let dayEnd = moment()
      .endOf("day")
      .unix();

    let qrContent = {
      showQR,
      visiting_time,
      valid_till,
      showQRPage: this.showQRPage,
      invite: true,
    };

    var decoded_json = {
      id: id,
      type: "invite",
      valid_till: dayEnd,
    };

    var stringified_json = JSON.stringify(decoded_json);
    var encoded_data = window.btoa(stringified_json);
    return (
      <div style={{ paddingBottom:"6%" }}>
        {showQR ? <QRBlock value={encoded_data} /> : <TimerBlockIcon />}
        <QRContent {...qrContent} />
      </div>
    );
  }
}
