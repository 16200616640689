import React from "react";
import { TimerBlock } from "components/QRComponent";
import moment from "moment";

export const styles = {
  text: {
    fontFamily: "Montserrat",
    fontWeight: 300,
    fontSize: "16px",
    color: "#000",
    maxWidth: "300px",
    textAlign: "center",
    lineHeight: "30px",
  },
};

const QRContent = (props) => {
  const { showQR, visiting_time, showQRPage, invite, valid_till } = props;

  let message =
    invite && !showQR
      ? "The QR will activate in"
      : "Kindly scan the above QR Code at the barricade to Check-in";

  let dayEnd = moment(valid_till).endOf("day");
  return (
    <React.Fragment>
      <p
        style={{
          ...styles.text,
          fontWeight: !showQR && 600,
          fontSize: !showQR && 18,
        }}
      >
        {message}
      </p>
      {invite && showQR && (
        <div style={{ margin: 10, paddingBottom: "4%" }}>
          <p
            style={{
              ...styles.text,
              textAlign: "center",
              margin: 0,
            }}
          >
            This QR is valid till
          </p>
          <p
            style={{
              ...styles.text,
              fontWeight: 600,
              lineHeight: "5px",
            }}
          >
            {moment(dayEnd).format("lll")}
          </p>
        </div>
      )}

      {invite && !showQR && (
        <TimerBlock
          visiting_time={visiting_time}
          showQRPage={showQRPage}
          showQR={showQR}
        />
      )}
    </React.Fragment>
  );
};

export default QRContent;
