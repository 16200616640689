import React, { Component } from "react";
import VisitEntry from "./visitentry";
import { visitDetails } from "api/visit";
import PageNotFound from "pages/ErrorPage";
import Expired from "pages/ExpiredPage";
import moment from "moment";
class Visit extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.visit_id = this.props.match.params.visit_id;
    this.state = {
      data: {},
      expired: false,
      visible: false,
      errorPage: false,
      showQR: false,
    };
  }
  componentDidMount() {
    this.fetchInviteData();
  }
  showQRPage = () => {
    this.setState({ showQR: true });
    this.timer = setInterval(() => {
      if (!this.showQR) {
        if (this.timer) {
          clearInterval(this.timer);
          this.timer = null;
        }
        return;
      }
      this.fetchInviteData();
    }, 10000);
  };
  fetchInviteData = () => {
    visitDetails(this.visit_id)
      .then(({ data }) => {
        this.computeStatus(data);
        this.setState({
          data,
          visible: true,
        });
      })
      .catch((err) => {
        this.setState({ errorPage: true });
        console.log(err);
      });
  };
  computeStatus = (data) => {
    let validTime =
      moment(data.created_at)
        .utc()
        .endOf("day")
        .format() <=
      moment()
        .utc()
        .format();
    if (validTime) {
      this.setState({ showQR: false, visible: false, expired: true });
    }
  };

  render() {
    const { data, visible, errorPage, expired } = this.state;

    if (errorPage) {
      return <PageNotFound />;
    }
    if (expired) {
      return <Expired />;
    }
    if (!visible) {
      return <div id="loader" />;
    } else {
      return <VisitEntry data={data} showQRPage={this.showQRPage} />;
    }
  }
}

export default Visit;
