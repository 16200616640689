import React from "react";
import { color } from "config";
const Timer = ({ showQR, days, hours, minutes, seconds }) => {
  return (
    <h1
      style={{
        color: color,
        marginTop: "0px",
        fontWeight: "bolder",
        padding: "0px 17px 0px 0px",
      }}
    >
      {!showQR && (
        <div className="qr-gen-container" style={{ width: "100%" }}>
          {days !== 0 && (
            <React.Fragment>
              <div>
                {days < 10 ? "0" + days : days}
                <p className="timer-label">Days </p>
              </div>
              <div>:</div>
            </React.Fragment>
          )}
          {hours !== 0 ? (
            <React.Fragment>
              <div>
                {hours < 10 ? "0" + hours : hours}
                <p className="timer-label">Hours</p>
              </div>
              <div>:</div>
            </React.Fragment>
          ) : days !== 0 ? (
            <React.Fragment>
              <div>
                00<p className="timer-label">Hours</p>
              </div>
              <div>:</div>
            </React.Fragment>
          ) : (
            ""
          )}
          {minutes !== 0 ? (
            <React.Fragment>
              <div>
                {minutes < 10 ? "0" + minutes : minutes}
                <p className="timer-label">Minutes</p>
              </div>
              <div>:</div>
            </React.Fragment>
          ) : hours !== 0 ? (
            <React.Fragment>
              <div>
                00<p className="timer-label">Minutes</p>
              </div>
              <div>:</div>
            </React.Fragment>
          ) : (
            ""
          )}
          {seconds !== 0 ? (
            <div>
              {seconds < 10 ? "0" + seconds : seconds}
              <p className="timer-label">Seconds</p>
            </div>
          ) : (
            <div>
              00<p className="timer-label">Seconds</p>
            </div>
          )}
        </div>
      )}
    </h1>
  );
};
export default Timer;
