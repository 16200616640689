import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import QRInvitations from "views/Invitation";
import Contractors from "views/Contractor";
import Home from "pages/HomePage";
import Invitation from "views/WebInvite";
import Visit from "views/visit";
import AlertDetails from "./views/AlertDetails";

const styles = {
  container: {
    backgroundColor: "#D5D5D5",
    minHeight: "100vh",
    padding: "16px",
  },
};

class App extends React.Component {
  render() {
    return (
      <div style={styles.container}>
        <Router>
          <Switch>
            <Route
              path="/invitations/new"
              render={(props) => <Invitation {...props} />}
              exact
            />

            <Route
              path="/invitations/:invitee_id"
              render={(props) => <QRInvitations {...props} />}
              exact
            />

            <Route
              path="/contractor/:id"
              render={(props) => <Contractors {...props} />}
              exact
            />
            <Route
              path="/visit-entry/:visit_id/details"
              render={(props) => <Visit {...props} />}
              exact
            />
            <Route
              path="/alerts/:id/details"
              render={(props) => <AlertDetails {...props} />}
              exact
            />
            <Route path="/home" render={(props) => <Home {...props} />} />
            <Route to="*">
              <Redirect to="/home" />
            </Route>
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
