import React from "react";
import Head from "components/Head";
import { SimplePhoneField, Toast } from "components";
import SubmitButton from "components/SubmitButton";
import { LoginImg } from "assets/icons";

const styles = {
  image: {
    margin: 30,
  },
  label: {
    color: "#451BAB",
    fontFamily: "Montserrat",
    fontSize: 18,
    fontWeight: 500,
  },
  heading: {
    fontSize: "22px",
    fontFamily: "Montserrat",
    color: "#000",
    textAlign: "center",
  },
};

const StaffForm = ({
  phone,
  validation,
  handleCustomChange,
  otpSubmit,
  disableBtn,
  validationErrors,
}) => {
  return (
    <React.Fragment>
      <Head subHeading="Smart QR Invite" />
        <div className="main-container">
          <div>
            <p style={styles.heading}>
              Please enter your registered Mobile Number
            </p>
          </div>
          <div style={styles.image}>
            <LoginImg />
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              otpSubmit();
            }}
            style={{ width: "100%", paddingTop: "4%" }}
          >
            <div>
              <SimplePhoneField
                required
                name="phone"
                label="Mobile Number"
                value={phone}
                onChange={handleCustomChange}
                error={validation.phone}
                inputProps={{
                  maxLength: 8,
                  minLength: 8,
                  type: "tel",
                  style: {
                    ...styles.label,
                    color: "#4A4A4A",
                    letterSpacing: 5,
                    textAlign: "left",
                  },
                }}
                InputLabelProps={{
                  style: {
                    ...styles.label,
                  },
                }}
              />
            </div>
            {validationErrors && <Toast msg={validationErrors} />}
            <div style={{ textAlign: "center", paddingTop: "4%" }}>
              <SubmitButton text="Send OTP" loading={disableBtn} />
            </div>
          </form>
        </div>
    </React.Fragment>
  );
};

export default StaffForm;
