import makeRequest from ".";

export function createVisit(requestBody, token) {
  return makeRequest({
    uri: `/api/v1/visits`,
    method: "POST",
    body: JSON.stringify(requestBody),
    authorization: token,
  });
}

export function visitDetails(id, token) {
  return makeRequest({
    uri: `/api/v1/visits/${id}/details`,
    authorization: token,
  });
}
