import React from "react";

const RightCard = (props) => {
  const {
    data: { name },
  } = props;

  return (
    <div
      className="qr-innercontainer1"
      style={{ padding: "95px 0px 142px 0px" }}
    >
      <p className="para-head">
        Dear <span style={{ textTransform: "capitalize" }}>{name},</span>
      </p>
      <p className="para" style={{ color: "#77838F", margin: "0px 15px 10px" }}>
        kindly scan this code in the entry gate
      </p>
    </div>
  );
};

export default RightCard;
