import React from "react";

const Divider = () => {
  return (
    <div
      style={{
        width: "100%",
        height: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        backgroundColor: "#fff",
      }}
    >
      <span
        style={{
          width: 25,
          height: 50,
          backgroundColor: "rgb(213,213,213)",
          position: "absolute",
          left: 0,
          borderTopRightRadius: 50,
          borderBottomRightRadius: 50,
        }}
      />
      <div
        style={{
          color: "rgb(213, 209, 209)",
          width: "100%",
          borderWidth: "0px 0px 2px",
          borderStyle: "dashed",
        }}
      ></div>
      <span
        style={{
          width: 25,
          height: 50,
          backgroundColor: "rgb(213,213,213)",
          borderTopLeftRadius: 50,
          borderBottomLeftRadius: 50,
          position: "absolute",
          right: 0,
        }}
      />
    </div>
  );
};

export default Divider;
