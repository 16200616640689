import React from "react";
import { Head, TicketBorder } from "components";
import LeftCard from "./leftCard";

const styles = {
  title: { textAlign: "center", width: "100%" },
};
const VisitEntry = ({ data, showQRPage }) => {
  return (
    <React.Fragment>
      <Head subHeading={"Smart QR Invite"} />
      <div className="qr-root">
        <div className="qr-container">
          <div className="qr-innercontainer">
            <div style={styles.title}></div>
            <LeftCard data={data} showQRPage={showQRPage} />
          </div>
          <TicketBorder />
        </div>
      </div>
    </React.Fragment>
  );
};
export default VisitEntry;
