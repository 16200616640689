import React from "react";
import "../assets/css/alerts.css";
import Logo from "../assets/img/logo.jpg";
import { Component } from "react";
import { alertDetails } from "api/alerts";
import { formatTime } from "helpers";
import PageNotFound from "pages/ErrorPage";
class AlertDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      alert_type: "",
      alert_time: "",
      alert_status: "",
    };
  }

  componentDidMount() {
    alertDetails(this.props.match.params.id)
      .then((res) => {
        this.setState({
          loader: false,
          alert_type_name: res.data.alert_type_name,
          from_time: res.data.from_time,
          status: res.data.status,
          s3_image_path: res.data.s3_image_path,
          resolved_at : res.data.resolved_at
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loader: false, errorPage: true });
      });
  }

  render() {
    const {
      loader,
      errorPage,
      alert_type_name,
      from_time,
      status,
      s3_image_path,
      resolved_at
    } = this.state;

    if (loader) {
      return <div id="loader" />;
    }

    if (errorPage) {
      return <PageNotFound />;
    }

    return (
      <div className="alert-container">
        <div className="alert-top-section">
          <span>
            <img src={Logo}  alt=""/>
          </span>
        </div>
        <div className="alert-bottom-section">
          <div className="alert-type">
            <p style={{ fontSize: 18 }}>{alert_type_name}</p>
          </div>
          <div className="time-status-container">
            <div>
              <span style={{ fontSize: 12 }}>
                {status === "resolved" ? "Resolved At" : "Triggered At"}
              </span>
              <p style={{ fontFamily: "Monsterrat", fontWeight: "600" }}>
                {status === "resolved" ? formatTime(resolved_at) :formatTime(from_time)}
              </p>
            </div>
            <div>
              <span style={{ fontSize: 12 }}>Status</span>
              <p style={{ fontFamily: "Monsterrat", fontWeight: "600" }}>
                {status || "-"}
              </p>
            </div>
          </div>
          {s3_image_path ? (
            <div className="alert-image-container">
              <img src={s3_image_path} alt="" />
            </div>
          ) : (
            <p style={{ textAlign: "center", paddingBottom: 20 }}>
              No Image Found
            </p>
          )}
        </div>
      </div>
    );
  }
}

export default AlertDetails;
