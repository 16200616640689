import React from "react";

const LoginImg = () => {
  return (
    <svg
      width={230}
      height={200}
      viewBox="0 0 203 173"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M193.195 172.031H2.244a1.324 1.324 0 010-2.648h190.949a1.324 1.324 0 01.002 2.648z"
        fill="#003C99"
      />
      <path
        d="M150.918 9.505c-1.345-7.961-13.604-11.555-13.365-6.36l-56.203.33C77.457 3.496 74.3 7 74.298 11.3l-.116 147.813c-.004 4.456 3.208 8.099 7.176 8.133l57.374.492c.356.748 1.023 1.428 2.073 1.997 6.58.141 9.735-3.109 10.889-6.783a8.87 8.87 0 00.57-3.224c-.23-1.925-.93-148.357-1.346-150.223z"
        fill="#66A2FF"
      />
      <path
        d="M140.19 169.759l-65.857-.425c-4.11-.026-7.433-3.768-7.426-8.353l.247-152.013c.007-4.42 3.279-8.012 7.309-8.025L139.04.734c4.062-.013 7.384 3.573 7.418 8.014l1.17 152.716c.036 4.606-3.294 8.322-7.438 8.295z"
        fill="#fff"
      />
      <path
        d="M141.101 168.653a5.925 5.925 0 01-.833.052l-65.838-.498c-3.531-.027-6.418-3.308-6.411-7.309l.227-151.846c.006-3.86 2.847-7.01 6.31-7.024l64.574-.28c.101 0 .204 0 .304.005 1.625.075 3.143.816 4.278 2.083 1.161 1.295 1.806 3.011 1.819 4.835l1.155 152.871c.029 3.621-2.365 6.679-5.585 7.111z"
        fill="#000"
      />
      <path
        d="M143.267 9.438l1.111 152.339-74.169-.444.19-151.895h72.868z"
        fill="#fff"
      />
      <path
        d="M117.462 86.095H97.607a3.064 3.064 0 01-3.054-3.055 3.064 3.064 0 013.054-3.054h19.855a3.064 3.064 0 013.055 3.054 3.064 3.064 0 01-3.055 3.055z"
        fill="#FF9F34"
      />
      <path
        d="M132.567 63.418h-51.34a3.064 3.064 0 01-3.055-3.055 3.064 3.064 0 013.055-3.054h51.34a3.063 3.063 0 013.054 3.054 3.063 3.063 0 01-3.054 3.055zM132.567 73.257h-51.34a3.064 3.064 0 01-3.055-3.055 3.064 3.064 0 013.055-3.054h51.34a3.063 3.063 0 013.054 3.055 3.063 3.063 0 01-3.054 3.054z"
        fill="#739AF0"
      />
      <path
        d="M90.813 70.472a1.076 1.076 0 11-2.151 0 1.076 1.076 0 012.151 0zM94.471 70.698a1.076 1.076 0 10-2.105-.448 1.076 1.076 0 002.105.448zM97.87 71.234a1.076 1.076 0 10-1.521-1.522 1.076 1.076 0 001.522 1.522zM101.558 71.233a1.076 1.076 0 10-1.523-1.521 1.076 1.076 0 001.523 1.521zM104.904 71.467a1.075 1.075 0 10-.822-1.987 1.075 1.075 0 00.822 1.987zM109.22 70.735a1.075 1.075 0 10-2.092-.495 1.075 1.075 0 002.092.495zM112.619 71.233a1.076 1.076 0 10-1.522-1.521 1.076 1.076 0 001.522 1.521zM116.306 71.233a1.076 1.076 0 10-1.522-1.521 1.076 1.076 0 001.522 1.521zM119.652 71.467a1.077 1.077 0 10-.824-1.99 1.077 1.077 0 00.824 1.99z"
        fill="#283654"
      />
      <path
        d="M114.904 28.968c4.414 4.414 4.414 11.57 0 15.984-4.413 4.413-11.569 4.413-15.983 0-4.414-4.414-4.414-11.57 0-15.984s11.57-4.413 15.983 0z"
        stroke="#2152FF"
      />
      <path
        d="M106.931 36.484c1.153 0 2.216-.561 2.895-1.418.502-.62.798-1.418.798-2.304 0-2.038-1.655-3.723-3.694-3.723a3.726 3.726 0 00-3.722 3.723c0 .887.295 1.684.797 2.304a3.741 3.741 0 002.926 1.418zM113.473 43.119c0 2.349-13.119 2.349-13.119 0l.004-2.738c.518-3.743 12.049-3.9 13.117 0l-.002 2.738z"
        fill="#005CEA"
      />
      <path
        d="M83.773 69.638v-.163a1.202 1.202 0 00-2.404 0v.163h-.183v2.167c0 .374 2.777.374 2.777 0v-2.167h-.19zm-1.975-.163c0-.416.256-.794.773-.794s.782.354.782.794v.163h-1.555v-.163zm.978 1.255v.626c0 .112-.097.192-.21.192a.185.185 0 01-.192-.192v-.626a.365.365 0 01-.193-.32.39.39 0 01.385-.385.39.39 0 01.385.384.384.384 0 01-.175.321zM82.575 60.142a.789.789 0 00.779-.786c0-.43-.35-.785-.78-.785a.786.786 0 000 1.57zM83.954 61.542c0 .496-2.782.496-2.782 0l.014-.577c.11-.79 2.543-.823 2.768 0v.577z"
        fill="#fff"
      />
      <path
        d="M100.557 61.26H89.306a.9.9 0 01-.897-.897.9.9 0 01.897-.896h11.251a.9.9 0 01.897.896.9.9 0 01-.897.897zM115.396 61.26h-11.252a.9.9 0 01-.896-.897.9.9 0 01.896-.896h11.252a.9.9 0 01.897.896.899.899 0 01-.897.897z"
        fill="#000"
        fillOpacity={0.65}
      />
      <path d="M132.205 90.77l.001-.182-.001.181z" fill="#D9FFFF" />
      <path
        d="M132.205 90.77l.001-.182-.001.181zM202.685 91.085l.001-.182-.001.182zM202.685 91.085l.001-.182-.001.182z"
        fill="#D9FFFF"
      />
      <path
        d="M167.575 74.207c-7.802 8.357-20.251 15.207-33.573 18.124l-.127 28.54c-.136 30.309 28.709 45.12 33.188 47.392 4.339-1.996 33.548-16.784 33.683-47.093l.127-28.54c-13.295-3.036-25.571-9.996-33.298-18.423z"
        fill="#fff"
      />
      <path
        d="M167.504 77.494c-8.043 7.378-19.185 13.177-31.438 16.338l-.121 27.034c-.055 12.37 4.859 23.56 14.607 33.262 6.404 6.374 13.138 10.328 16.555 12.105l.468-.241V77.558l-.071-.064z"
        fill="#80B2FF"
      />
      <path
        d="M167.575 77.557v88.434c3.553-1.848 10.011-5.669 16.196-11.715 9.833-9.614 14.848-20.759 14.903-33.13l.121-27.034c-12.189-3.26-23.25-9.135-31.22-16.555z"
        fill="#4D93FF"
      />
      <path
        d="M192.124 104.654c-9.328 1.439-22.029 9.946-30.043 20.176-1.125-4.824-6.17-9.227-8.81-10.19l-7.998 8.039c4.993 2.251 10.451 8.627 11.174 15.155l7.99 1.238c3.368-6.654 12.068-22.571 29.376-31.129-.332-1.302-1.554-3.103-1.689-3.289z"
        fill="#0046B3"
      />
      <path
        d="M40.172 56.123l-1.406 5.654-.255 1.051c-.285 1.337-9.107.768-8.482-.795l.526-1.15 2.713-5.954 6.904 1.194z"
        fill="#F7A58E"
      />
      <path
        d="M45.33 47.736c.09 6.13-2.755 11.117-7.209 11.095-4.453-.022-8.762-3.8-8.918-11.173-.1-4.697 3.651-8.49 8.105-8.468 4.454.02 7.954 3.848 8.023 8.546z"
        fill="#F7A58E"
      />
      <path
        d="M37.44 37.503s8.875.438 9.613 7.16c.986 5.899-3.44 1.562-5.247 5.521-2.07 4.535.04 7.853 2.729 8.754-2.392 3.339-16.066 3.219-19.584-2.64-2.41-7.347 1-18.387 12.49-18.795z"
        fill="#000"
      />
      <path
        d="M43.664 49.942a1.653 1.653 0 11-3.295.268 1.653 1.653 0 013.295-.268z"
        fill="#F7A58E"
      />
      <path
        d="M42.304 164.003c.416-.44 2.378-.447 2.557.205.18.651-.167 1.117.662 2.151-.477.138-2.166.15-2.684 0-.518-.152-.951-1.915-.535-2.356z"
        fill="#DFDFDF"
      />
      <path
        d="M39.062 166.23h4.782c.447-20.235.362-42.262.2-58.852-.057-5.941-.22-8.946-1.298-12.512-2.176-7.202-12.408-4.985-13.134 1.543-.303 3.622-.524 10.69 6.173 34.705-2.9 10.573 2.158 23.251 3.277 35.116z"
        fill="#00050D"
      />
      <path
        d="M46.522 166.539c-.704-.038-2.35-.715-2.659-1.184-.457.511-1.409.653-2.557.481-1.357-.202-1.536-.833-2.509-.749-.41 1.141-1.41 3.7-.445 4.295h10.053c.897-.82.873-2.568-1.883-2.843zM17.96 164.515c.35-.37 2-.376 2.15.172.15.547-.14.939.555 1.807-.4.116-1.821.126-2.256 0-.434-.127-.799-1.608-.449-1.979z"
        fill="#DFDFDF"
      />
      <path
        d="M33.718 96.225c-.978-7.46-14.249-6.694-15.131-.18-.318 3.504-1.293 8.78 1.202 34.814-5.261 9.935-3.775 24.191-5.347 34.685-.173 1.596 4.35 1.98 4.7.557 4.82-19.47 11.556-41.314 14.06-57.511.909-5.871 1-8.67.516-12.365z"
        fill="#00050D"
      />
      <path
        d="M21.518 166.23c-.704-.037-1.743-.953-2.052-1.422-.458.511-1.33 1.2-2.479 1.028-1.357-.202-1.536-.833-2.509-.749-.41 1.141-1.41 3.701-.445 4.295h9.78c.896-.82.46-2.877-2.295-3.152z"
        fill="#DFDFDF"
      />
      <path
        d="M56.1 56.739l-2.475.55c.056 1.301-.1 3.382 0 5.679l4.164-.549a122.412 122.412 0 00-1.689-5.68z"
        fill="#F7A58E"
      />
      <path
        d="M53.625 62.967c-.092 4.551-.214 6.502-.251 8.294L41.022 63.34c-3.764-1.142-8.153 6.308-3.393 9.669 0 0 16.449 9.288 20.15 6.613 2.002-1.448 1.482-11.83.01-17.204l-4.164.55z"
        fill="#DFDFDF"
      />
      <path
        d="M57.218 54.317c-1.144-1.34-3.655-.874-3.846-.837l.242 3.709c.056 1.832 2.329 1.894 3.253.315.001 0 1.342-1.867.351-3.187z"
        fill="#F7A58E"
      />
      <path
        d="M54.589 53.83c-.834-4.04-1.675-3.5-1.675-3.5s.134 1.429.511 3.948c.283 1.428 1.266.567 1.164-.447z"
        fill="#F7A58E"
      />
      <path
        d="M43.94 73.987c.838-4.082 1.289-8.112-2.92-10.646-2.494-1.504-10.511-2.576-14.26-1.335-3.778 1.688-2.96 9.05-3.669 20.679-2.603 5.803-3.735 7.709-4.506 13.36 0 0 1.393 4.259 11.205 4.264 4.455.003 11.016-.393 14-2.666-.297-3.854-1.909-7.567-2.58-13.024 0 0 2.67-10.164 2.73-10.632z"
        fill="#DFDFDF"
      />
      <path
        d="M24.746 62.826c-4.605 3.516-9.096 9.044-11.219 11.98-2.123 2.936-7.62 14.735-7.62 14.735l3.498 2.58 9.465-12.896 11.563-9.485c4.013-4.095-1.202-10.338-5.687-6.914z"
        fill="#DFDFDF"
      />
      <path
        d="M4.44 92.534l2.267-4.686c.534 1.408 3.13 2.695 3.876 2.667 0 0-2.439 3.51-3.036 4.106-.595.596-3.058-.414-3.108-2.087z"
        fill="#F7A58E"
      />
      <path
        d="M8.121 93.838c-.008.035.001.083-.008.118-.114 1.705-1.425 2.512-1.78 2.703-.027.023-.059.03-.09.037 1.233-.247 1.602 1.405.975 1.786-1.814 1.228-4.4-.461-4.62-1.486-.222-1.041 1.845-4.482 1.845-4.482 1.054-.852 3.753-.72 3.678 1.324z"
        fill="#F7A58E"
      />
      <path
        d="M8.114 93.954c-.073 1.927-1.81 2.721-1.842 2.727-1.017-.028-1.379-.09-2.348-.087.633-.309 1.931-1.13 1.428-2.724.995-.76 2.49-.096 2.762.084z"
        fill="#E6856A"
      />
      <path
        d="M8.14 93.735c.267 1.592.154 3.859-.225 4.856 0 0-.975.05-.883-1.791.024-.48-.045-1.52-.52-1.868-1.46-.964-1.695-1.85-1.159-2.576.713-.967 2.73.015 2.786 1.38zM3.62 98.084c.793.55 2.89 1.614 3.221 1.352.33-.263-1.346-2.048-2.428-2.284-1.083-.237-1.198.65-.792.932z"
        fill="#F7A58E"
      />
    </svg>
  );
};

export default LoginImg;
