import React, { Component } from "react";

import { Timer } from "components/QRComponent";
class TimerBlock extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.state = {
      days: 0,
      minutes: 0,
      hours: 0,
      seconds: 0,
      time: 0,
    };
  }
  componentDidMount() {
    const { visiting_time } = this.props;

    this.getTimeDifference(visiting_time);
    this.timer = setInterval(() => this.getTimeDifference(visiting_time), 1000);
  }

  getTimeDifference = (visiting_time) => {
    const { showQRPage } = this.props;
    const time = Date.parse(visiting_time) - Date.parse(new Date());
    const days = Math.floor(time / (1000 * 60 * 60 * 24));
    const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((time / 1000 / 60) % 60);
    const seconds = Math.floor((time / 1000) % 60);
    this.setState({ days, hours, minutes, seconds, time });
    if (time <= 0) {
      clearInterval(this.timer);
      this.timer = null;
      showQRPage();
    }
  };
  render() {
    const { showQR } = this.props;
    const { days, minutes, seconds, hours } = this.state;
    return (
      <Timer
        showQR={showQR}
        days={days}
        minutes={minutes}
        seconds={seconds}
        hours={hours}
      />
    );
  }
}

export default TimerBlock;
