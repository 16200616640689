import React, { Component } from "react";
import { QRBlock } from "components/QRComponent";
export default class LeftCard extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.state = {
      data: {},
      showQR: false,
    };
  }

  render() {
    const { data } = this.props;
    var decoded_json = {
      id: data.id,
      type: "contractor",
    };

    var stringified_json = JSON.stringify(decoded_json);
    var encoded_data = window.btoa(stringified_json);
    return (
      <div style={{ paddingBottom: "16%", paddingTop: "10%" }}>
        <QRBlock value={encoded_data} />
      </div>
    );
  }
}
